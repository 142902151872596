export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  messages: {
    en: {
      first_name: "First name",
      last_name: "Last name",
      phone_number: "Phone number",
      logo: "Logo",
      personal_photo: "Personal photo",
      personal_message: "Personal message",
      call_to_action_button: "Call to action button",
      button_text: "Button text",
      none: "None",
      preset: "Preset",
      custom: "Custom",
      preview: "Preview",
      share: "Share",
      personalize: "Personalize",
      view: "View",
      complete_your_profile: "Complete Your Profile",
      personal_details: "Personal Details",
      customize_content_experience: "Customize Content Experience",
      share_on_whatsapp: "Share on WhatsApp",
      language: "Language",
      ai_description: "Our AI Assistant is writing something just for you! Simply copy, paste, edit and share.",
      ai_regenerate: "Try something different",
      copy_text_create_post: "Copy text & create post",
      copy_link_to_content_experience: "Copy link to your content experience",
      email: "Email",
      qr_code: "QR Code",
      link: "Link",
      my_experiences: "My Experiences",
      my_profile: "My Profile",
      about_my_business: "About My Business",
      i_am_a_travel_advisor: "I am a travel advisor/agency or similar third-party seller of travel",
      host_agency: "Host Agency",
      agency_association_consortium: "Agency Association / Consortium",
      booking_email: "Booking Email",
      booking_phone_number: "Booking Phone Number",
      iata_used_for_booking: "IATA used for booking",
      clia_used_for_booking: "CLIA used for booking",
      company_name: "Company Name",
      business_languages: "Languages I conduct business in",
      update: "Update",
      saving: "Saving",
      saved: "Saved",
      logout: "Logout",
      cobrand_and_share: "Co-brand & share",
      your_content_experiences: "Your Content Experiences",
      your_experiences: "Your Experiences",
      new_experiences: "New Experiences",
      all_content_experiences: "All Content Experiences",
      search: "Search",
      experiences: "Experiences",
      cobranded_website: "Co-branded website",
      about: "About",
      your_partnerName_content_experiences: "Your :partnerName Content Experiences",
      content_experiences_description:
        "Content experiences enable you to quickly deliver inspirational content in a highly personalized way. **Click an experience below to get started.**",
      cobrand_the_partnerName_website: "Co-brand the :partnerName website",
      cobrand_website_url_description:
        "Here's your personal, co-branded :partnerName website. Safely share this link with clients - we've transformed the site just for you.",
      copy_link: "Copy Link",
      copied: "Copied",
      cobrand_website_any_url_description:
        "It gets even better! You can co-brand any page on the :partnerName website. Simply enter any :partnerName webpage to get your co-branded link.",
      cobrand: "Co-brand",
      contact: "Contact",
      for_travel_brands: "For travel brands",
      recommend: "Recommend",
      recommend_us_to_suppliers: "Recommend us to your favorite suppliers",
      recommend_us_description:
        "Don't you wish all of your suppliers made marketing this easy? Let your suppliers know they can create content experiences to power your marketing. Click the button and let us craft an email for you!",
      cancel: "Cancel",
      copy_and_paste_in_email: "Copy (and paste into an email)",
      pagination: ":itemsFrom - :itemsTo of :itemsTotal items.",
      choose_a_content_experience: "Choose a content experience",
      sign_in: "Sign In",
      get_started: "Get Started",
      sign_in_to_account: "Sign in to your account",
      sign_in_description: "Enter your email and we'll send a link that logs you in automatically.",
      email_address: "Email address",
      no_account: "Don't have an account?",
      sign_up_title: "Sign up to start sharing co-branded experiences",
      sign_up_description: "In a matter of seconds, you can co-brand & share our content experiences to build your business.",
      create_account: "Create account",
      personalize_your_content_experience: "Personalize this content experience",
      invitation_description:
        "We've crafted this content experience to power your marketing and drive conversion. It's easy to customize—simply enter your email to get started!",
      enter_email_legal: "By entering your email address, you agree to the",
      and: "and",
      magic_link_email_sent_title: "Check your email!",
      magic_link_email_sent_description: "To get started, click the magic link button in the email we sent to **:userEmail**",
      magic_link_legal: "By clicking the magic link, you agree to the",
      preview_description: "This is a preview. To see the actual guest experience, log out.",
      name: "Name",
      logo_personal_photo: "Logo & Personal Photo",
      location: "Location",
      consortium: "Consortium",
      booking_email_phone: "Booking Email & Phone",
      home: "Home",
      update_profile: "Update Profile",
      profile_instructions: "Please confirm or fill in the highlighted fields.",
      select: "Select",
      country: "Country",
      state_region: "State / Region",
      homepage_title: "Supercharge your marketing & sales efforts",
      homepage_subtitle: "with free co-branded content experiences",
      share_qr_code_description: "Download the code or screenshot it for even faster sharing.",
      download_qr_code: "Download QR code",
      bilbert_email_start: "I'm crafting an email, personally for you! Give me a few seconds",
      bilbert_email_end: "All done! Simply click the button below to copy, and then paste it into an email and send!",
      ai_robot: "AI Robot",
      sign_up: "Sign Up",
      already_signed_up: "Already signed up?",
      cookies_description: "We use cookies to improve your experience.",
      learn_more: "Learn More",
      accept: "Accept",
      hooray: "Hooray!",
      experience_creation_description: "Give us a moment while we create your co-branded experience...",
      share_link_everywhere: "Share your link everywhere",
      sales_links_header: "Links to share with partners",
      sales_links_description:
        "Attending a meeting or event, doing a webinar, sending out an email? This is a complete, up-to-date resource with everything you need to invite partners to co-brand & share your content experiences.",
      step_one_header: "Copy the invitation link or download the QR code",
      step_one_description:
        "Copy the invitation link or download the QR code for either all :partnerName content experiences, or choose a specific content experience.",
      step_one_tip: "don't forget to **test** your QR code before sharing!",
      copy_invite_link: "Copy invite link",
      copied_to_clipboard: "Copied to clipboard",
      or_choose_content_experience: "Or choose a specific content experience:",
      step_two_header: "Put it into your webinar presentation, email, and social posts",
      step_two_description: "Put it into your webinar presentation, email, social post... wherever you are engaging with trade partners.",
      step_three_header: "Add explanatory copy",
      step_three_description: "Add explanatory copy to help partners understand why you are sharing the links and QR codes.",
      step_three_tip: "don't forget to paste in your link and/or QR code!",
      sales_example_one: "It's the easiest way to sell :partnerName. Click to get started!",
      sales_example_two: "Click to instantly co-brand & share our latest content experience.",
      sales_example_three: "Click to instantly begin selling :partnerName",
      just_a_moment: "Just a moment",
      thats_strange: "That's strange...",
      invalid_magic_link: "This magic link is invalid. Please re-click the magic link button or paste in the associated magic URL",
      oops: "Oops!",
      magic_link_used: "Magic links are for one-time use and this one has already been used. Please re-enter your email address to receive a new magic link.",
      get_new_magic_link: "Get your new magic link!",
      new_magic_link_sent: "Your magic link expired so we have sent you a new one.",
      magic_link_check_email: "Check your email and click to login",
      cobrand_our_website: "Co-brand our website",
      required_field: "This field is required.",
      sales_example_description: "Here are some examples to get you started:",
      confirm: "Confirm",
    },

    it: {
      first_name: "Nome",
      last_name: "Cognome",
      phone_number: "Numero di telefono",
      logo: "Logo",
      personal_photo: "Foto personale",
      personal_message: "Messaggio personale",
      call_to_action_button: "Pulsante di invito all'azione",
      button_text: "Testo del pulsante",
      none: "Nessuno",
      preset: "Preimpostato",
      custom: "Personalizzato",
      preview: "Anteprima",
      share: "Condividi",
      personalize: "Personalizza",
      view: "Visualizza",
      complete_your_profile: "Completa il tuo profilo",
      personal_details: "Dettagli personali",
      customize_content_experience: "Personalizza l'esperienza di contenuto",
      share_on_whatsapp: "Condividi su WhatsApp",
      language: "Lingua",
      ai_description: "Il nostro assistente AI sta scrivendo qualcosa solo per te! Basta copiare, incollare, modificare e condividere.",
      ai_regenerate: "Prova qualcosa di diverso",
      copy_text_create_post: "Copia il testo e crea il post",
      copy_link_to_content_experience: "Copia il link per la tua esperienza di contenuto",
      email: "E-mail",
      qr_code: "Codice QR",
      link: "Link",
      my_experiences: "Le mie esperienze",
      my_profile: "Il mio profilo",
      about_my_business: "Informazioni sulla mia attività",
      i_am_a_travel_advisor: "Sono un agente di viaggio/agenzia o un venditore di terze parti simile",
      host_agency: "Agenzia ospitante",
      agency_association_consortium: "Associazione di agenzie / Consorzio",
      booking_email: "Email di prenotazione",
      booking_phone_number: "Numero di telefono di prenotazione",
      iata_used_for_booking: "IATA utilizzato per prenotazioni",
      clia_used_for_booking: "CLIA utilizzato per prenotazioni",
      company_name: "Nome dell'azienda",
      business_languages: "Lingue in cui svolgo attività",
      update: "Aggiorna",
      saving: "Salvataggio",
      saved: "Salvato",
      logout: "Esci",
      cobrand_and_share: "Co-brandizza e condividi",
      your_content_experiences: "Le tue esperienze di contenuto",
      your_experiences: "Le tue esperienze",
      new_experiences: "Nuove esperienze",
      all_content_experiences: "Tutte le esperienze di contenuto",
      search: "Cerca",
      experiences: "Esperienze",
      cobranded_website: "Sito web co-branded",
      about: "Informazioni",
      your_partnerName_content_experiences: "Le tue esperienze di contenuto :partnerName",
      content_experiences_description:
        "Le esperienze di contenuto ti permettono di offrire contenuti ispiratori in modo altamente personalizzato. **Clicca su un'esperienza qui sotto per iniziare.**",
      cobrand_the_partnerName_website: "Co-brandizza il sito web di :partnerName",
      cobrand_website_url_description:
        "Ecco il tuo sito web personale co-branded di :partnerName. Condividi questo link in modo sicuro con i clienti - abbiamo trasformato il sito solo per te.",
      copy_link: "Copia il link",
      copied: "Copiato",
      cobrand_website_any_url_description:
        "Diventa ancora più interessante! Puoi co-brandizzare qualsiasi pagina del sito web di :partnerName. Basta inserire qualsiasi pagina di :partnerName per ottenere il tuo link co-branded.",
      cobrand: "Co-brandizza",
      contact: "Contatto",
      for_travel_brands: "Per i brand di viaggio",
      recommend: "Raccomanda",
      recommend_us_to_suppliers: "Raccomandaci ai tuoi fornitori preferiti",
      recommend_us_description:
        "Non sarebbe bello se tutti i tuoi fornitori rendessero il marketing così facile? Fai sapere ai tuoi fornitori che possono creare esperienze di contenuto per alimentare il tuo marketing. Clicca sul pulsante e lascia che scriviamo un'email per te!",
      cancel: "Annulla",
      copy_and_paste_in_email: "Copia (e incolla in un'email)",
      pagination: ":itemsFrom - :itemsTo di :itemsTotal articoli.",
      choose_a_content_experience: "Scegli un'esperienza di contenuto",
      sign_in: "Accedi",
      get_started: "Inizia",
      sign_in_to_account: "Accedi al tuo account",
      sign_in_description: "Inserisci la tua email e ti invieremo un link che ti farà accedere automaticamente.",
      email_address: "Indirizzo e-mail",
      no_account: "Non hai un account?",
      sign_up_title: "Iscriviti per iniziare a condividere esperienze co-branded",
      sign_up_description: "In pochi secondi, puoi co-brandizzare e condividere le nostre esperienze di contenuto per costruire la tua attività.",
      create_account: "Crea un account",
      personalize_your_content_experience: "Personalizza questa esperienza di contenuto",
      invitation_description:
        "Abbiamo creato questa esperienza di contenuto per alimentare il tuo marketing e aumentare la conversione. È facile da personalizzare—basta inserire la tua email per iniziare!",
      enter_email_legal: "Inserendo il tuo indirizzo email, accetti il",
      and: "e",
      magic_link_email_sent_title: "Controlla la tua email!",
      magic_link_email_sent_description: "Per iniziare, clicca sul pulsante del link magico nell'email che abbiamo inviato a **:userEmail**",
      magic_link_legal: "Cliccando sul link magico, accetti il",
      preview_description: "Questa è un'anteprima. Per vedere l'esperienza reale dell'ospite, esci.",
      name: "Nome",
      logo_personal_photo: "Logo e Foto personale",
      location: "Posizione",
      consortium: "Consorzio",
      booking_email_phone: "Email e telefono di prenotazione",
      home: "Home",
      update_profile: "Aggiorna il profilo",
      profile_instructions: "Conferma o compila i campi evidenziati.",
      select: "Seleziona",
      country: "Paese",
      state_region: "Stato / Regione",
      homepage_title: "Potenzia il tuo marketing e le tue vendite",
      homepage_subtitle: "con esperienze di contenuto co-branded gratuite",
      share_qr_code_description: "Scarica il codice o fai uno screenshot per condividerlo ancora più velocemente.",
      download_qr_code: "Scarica il codice QR",
      bilbert_email_start: "Sto creando un'email, personalmente per te! Dammi qualche secondo",
      bilbert_email_end: "Fatto! Clicca semplicemente il pulsante qui sotto per copiare, poi incollalo in un'email e invialo!",
      ai_robot: "Robot AI",
      sign_up: "Iscriviti",
      already_signed_up: "Già iscritto?",
      cookies_description: "Usiamo i cookie per migliorare la tua esperienza.",
      learn_more: "Scopri di più",
      accept: "Accetta",
      hooray: "Evviva!",
      experience_creation_description: "Aspetta un momento mentre creiamo la tua esperienza co-branded...",
      share_link_everywhere: "Condividi il tuo link ovunque",
      sales_links_header: "Link da condividere con i partner",
      sales_links_description:
        "Stai partecipando a una riunione o a un evento, facendo un webinar, inviando un'email? Questa è una risorsa completa e aggiornata con tutto ciò che ti serve per invitare i partner a co-brandizzare e condividere le tue esperienze di contenuto.",
      step_one_header: "Copia il link di invito o scarica il codice QR",
      step_one_description:
        "Copia il link di invito o scarica il codice QR per tutte le esperienze di contenuto :partnerName, oppure scegli un'esperienza di contenuto specifica.",
      step_one_tip: "Non dimenticare di **testare** il tuo codice QR prima di condividerlo!",
      copy_invite_link: "Copia il link di invito",
      copied_to_clipboard: "Copiato negli appunti",
      or_choose_content_experience: "Oppure scegli un'esperienza di contenuto specifica:",
      step_two_header: "Inseriscilo nella tua presentazione del webinar, nell'email e nei post sui social",
      step_two_description:
        "Inseriscilo nella tua presentazione del webinar, nell'email, nel post sui social... ovunque tu stia interagendo con i partner commerciali.",
      step_three_header: "Aggiungi un testo esplicativo",
      step_three_description: "Aggiungi un testo esplicativo per aiutare i partner a capire perché stai condividendo i link e i codici QR.",
      step_three_tip: "Non dimenticare di incollare il tuo link e/o codice QR!",
      sales_example_one: "È il modo più semplice per vendere :partnerName. Clicca per iniziare!",
      sales_example_two: "Clicca per co-brandizzare e condividere istantaneamente la nostra ultima esperienza di contenuto.",
      sales_example_three: "Clicca per iniziare a vendere istantaneamente :partnerName",
      just_a_moment: "Un momento",
      thats_strange: "È strano...",
      invalid_magic_link: "Questo link magico non è valido. Clicca di nuovo sul pulsante del link magico o incolla l'URL magico associato",
      oops: "Ops!",
      magic_link_used:
        "I link magici sono per uso una sola volta e questo è già stato utilizzato. Inserisci di nuovo il tuo indirizzo email per ricevere un nuovo link magico.",
      get_new_magic_link: "Ottieni il tuo nuovo link magico!",
      new_magic_link_sent: "Il tuo link magico è scaduto, quindi te ne abbiamo inviato uno nuovo.",
      magic_link_check_email: "Controlla la tua email e clicca per accedere",
      cobrand_our_website: "Co-brandizza il nostro sito web",
      required_field: "Questo campo è obbligatorio.",
      sales_example_description: "Ecco alcuni esempi per aiutarti a iniziare:",
      confirm: "Conferma",
    },
    es: {
      first_name: "Nombre",
      last_name: "Apellido",
      phone_number: "Número de teléfono",
      logo: "Logo",
      personal_photo: "Foto personal",
      personal_message: "Mensaje personal",
      call_to_action_button: "Botón de llamada a la acción",
      button_text: "Texto del botón",
      none: "Ninguno",
      preset: "Preestablecido",
      custom: "Personalizado",
      preview: "Vista previa",
      share: "Compartir",
      personalize: "Personalizar",
      view: "Ver",
      complete_your_profile: "Completa tu perfil",
      personal_details: "Detalles personales",
      customize_content_experience: "Personaliza la experiencia de contenido",
      share_on_whatsapp: "Compartir en WhatsApp",
      language: "Idioma",
      ai_description: "¡Nuestro asistente de IA está escribiendo algo solo para ti! Simplemente copia, pega, edita y comparte.",
      ai_regenerate: "Prueba algo diferente",
      copy_text_create_post: "Copiar texto y crear publicación",
      copy_link_to_content_experience: "Copiar enlace a tu experiencia de contenido",
      email: "Correo electrónico",
      qr_code: "Código QR",
      link: "Enlace",
      my_experiences: "Mis experiencias",
      my_profile: "Mi perfil",
      about_my_business: "Acerca de mi negocio",
      i_am_a_travel_advisor: "Soy un agente de viajes/una agencia o vendedor de viajes de terceros similar",
      host_agency: "Agencia anfitriona",
      agency_association_consortium: "Asociación de agencias / Consorcio",
      booking_email: "Correo electrónico de reservas",
      booking_phone_number: "Número de teléfono de reservas",
      iata_used_for_booking: "IATA utilizado para reservas",
      clia_used_for_booking: "CLIA utilizado para reservas",
      company_name: "Nombre de la empresa",
      business_languages: "Idiomas en los que realizo negocios",
      update: "Actualizar",
      saving: "Guardando",
      saved: "Guardado",
      logout: "Cerrar sesión",
      cobrand_and_share: "Co-brandizar y compartir",
      your_content_experiences: "Tus experiencias de contenido",
      your_experiences: "Tus experiencias",
      new_experiences: "Nuevas experiencias",
      all_content_experiences: "Todas las experiencias de contenido",
      search: "Buscar",
      experiences: "Experiencias",
      cobranded_website: "Sitio web co-brandizado",
      about: "Acerca de",
      your_partnerName_content_experiences: "Tus experiencias de contenido :partnerName",
      content_experiences_description:
        "Las experiencias de contenido te permiten entregar rápidamente contenido inspirador de una manera altamente personalizada. **Haz clic en una experiencia abajo para empezar.**",
      cobrand_the_partnerName_website: "Co-brandiza el sitio web de :partnerName",
      cobrand_website_url_description:
        "Aquí tienes tu sitio web personal, co-brandizado de :partnerName. Comparte este enlace de forma segura con tus clientes: hemos transformado el sitio solo para ti.",
      copy_link: "Copiar enlace",
      copied: "Copiado",
      cobrand_website_any_url_description:
        "¡Y se pone aún mejor! Puedes co-brandizar cualquier página en el sitio web de :partnerName. Simplemente introduce cualquier página web de :partnerName para obtener tu enlace co-brandizado.",
      cobrand: "Co-brandizar",
      contact: "Contacto",
      for_travel_brands: "Para marcas de viajes",
      recommend: "Recomendar",
      recommend_us_to_suppliers: "Recomiéndanos a tus proveedores favoritos",
      recommend_us_description:
        "¿No te gustaría que todos tus proveedores hicieran el marketing tan fácil? Hazles saber que pueden crear experiencias de contenido para potenciar tu marketing. ¡Haz clic en el botón y deja que redactemos un correo electrónico para ti!",
      cancel: "Cancelar",
      copy_and_paste_in_email: "Copiar (y pegar en un correo electrónico)",
      pagination: ":itemsFrom - :itemsTo de :itemsTotal artículos.",
      choose_a_content_experience: "Elige una experiencia de contenido",
      sign_in: "Iniciar sesión",
      get_started: "Comenzar",
      sign_in_to_account: "Inicia sesión en tu cuenta",
      sign_in_description: "Ingresa tu correo electrónico y te enviaremos un enlace que te iniciará sesión automáticamente.",
      email_address: "Dirección de correo electrónico",
      no_account: "¿No tienes cuenta?",
      sign_up_title: "Regístrate para comenzar a compartir experiencias co-brandizadas",
      sign_up_description: "En segundos, puedes co-brandizar y compartir nuestras experiencias de contenido para construir tu negocio.",
      create_account: "Crear cuenta",
      personalize_your_content_experience: "Personaliza esta experiencia de contenido",
      invitation_description:
        "Hemos creado esta experiencia de contenido para potenciar tu marketing y aumentar la conversión. Es fácil de personalizar—basta ingresar tu correo electrónico para comenzar!",
      enter_email_legal: "Al ingresar tu correo electrónico, aceptas los",
      and: "y",
      magic_link_email_sent_title: "¡Revisa tu correo electrónico!",
      magic_link_email_sent_description: "Para comenzar, haz clic en el botón del enlace mágico en el correo que enviamos a **:userEmail**",
      magic_link_legal: "Al hacer clic en el enlace mágico, aceptas los",
      preview_description: "Esta es una vista previa. Para ver la experiencia real del invitado, cierra sesión.",
      name: "Nombre",
      logo_personal_photo: "Logo y Foto personal",
      location: "Ubicación",
      consortium: "Consorcio",
      booking_email_phone: "Correo electrónico y teléfono de reservas",
      home: "Inicio",
      update_profile: "Actualizar perfil",
      profile_instructions: "Por favor confirma o llena los campos resaltados.",
      select: "Seleccionar",
      country: "País",
      state_region: "Estado / Región",
      homepage_title: "Potencia tus esfuerzos de marketing y ventas",
      homepage_subtitle: "con experiencias de contenido co-brandizadas gratuitas",
      share_qr_code_description: "Descarga el código o haz una captura de pantalla para compartirlo aún más rápido.",
      download_qr_code: "Descargar código QR",
      bilbert_email_start: "Estoy creando un correo electrónico, ¡personalmente para ti! Dame unos segundos",
      bilbert_email_end: "¡Listo! Simplemente haz clic en el botón abajo para copiarlo, luego pégalo en un correo electrónico y envíalo.",
      ai_robot: "Robot AI",
      sign_up: "Registrarse",
      already_signed_up: "¿Ya estás registrado?",
      cookies_description: "Usamos cookies para mejorar tu experiencia.",
      learn_more: "Aprender más",
      accept: "Aceptar",
      hooray: "¡Hurra!",
      experience_creation_description: "Danos un momento mientras creamos tu experiencia co-brandizada...",
      share_link_everywhere: "Comparte tu enlace en todas partes",
      sales_links_header: "Enlaces para compartir con socios",
      sales_links_description:
        "¿Asistiendo a una reunión o evento, haciendo un seminario web, enviando un correo electrónico? Este es un recurso completo y actualizado con todo lo que necesitas para invitar a los socios a co-brandizar y compartir tus experiencias de contenido.",
      step_one_header: "Copia el enlace de invitación o descarga el código QR",
      step_one_description:
        "Copia el enlace de invitación o descarga el código QR para todas las experiencias de contenido de :partnerName, o elige una experiencia de contenido específica.",
      step_one_tip: "¡No olvides **probar** tu código QR antes de compartirlo!",
      copy_invite_link: "Copiar enlace de invitación",
      copied_to_clipboard: "Copiado al portapapeles",
      or_choose_content_experience: "O elige una experiencia de contenido específica:",
      step_two_header: "Ponlo en tu presentación de seminario web, correo electrónico y publicaciones en redes sociales",
      step_two_description:
        "Ponlo en tu presentación de seminario web, correo electrónico, publicación en redes sociales... donde estés interactuando con socios comerciales.",
      step_three_header: "Añade un texto explicativo",
      step_three_description: "Añade un texto explicativo para ayudar a los socios a entender por qué estás compartiendo los enlaces y códigos QR.",
      step_three_tip: "¡No olvides pegar tu enlace y/o código QR!",
      sales_example_one: "Es la forma más fácil de vender :partnerName. ¡Haz clic para comenzar!",
      sales_example_two: "Haz clic para co-brandizar y compartir nuestra última experiencia de contenido.",
      sales_example_three: "Haz clic para comenzar a vender :partnerName",
      just_a_moment: "Un momento",
      thats_strange: "Eso es extraño...",
      invalid_magic_link: "Este enlace mágico no es válido. Por favor, haz clic nuevamente en el botón del enlace mágico o pega la URL mágica asociada",
      oops: "¡Ups!",
      magic_link_used:
        "Los enlaces mágicos son para un solo uso y este ya ha sido utilizado. Por favor, vuelve a ingresar tu correo electrónico para recibir un nuevo enlace mágico.",
      get_new_magic_link: "¡Obtén tu nuevo enlace mágico!",
      new_magic_link_sent: "Tu enlace mágico expiró, así que te hemos enviado uno nuevo.",
      magic_link_check_email: "Revisa tu correo electrónico y haz clic para iniciar sesión",
      cobrand_our_website: "Co-brandiza nuestro sitio web",
      required_field: "Este campo es obligatorio.",
      sales_example_description: "Aquí tienes algunos ejemplos para empezar:",
      confirm: "Confirmar",
    },
    pt: {
      first_name: "Nome",
      last_name: "Sobrenome",
      phone_number: "Número de telefone",
      logo: "Logo",
      personal_photo: "Foto pessoal",
      personal_message: "Mensagem pessoal",
      call_to_action_button: "Botão de chamada à ação",
      button_text: "Texto do botão",
      none: "Nenhum",
      preset: "Predefinido",
      custom: "Personalizado",
      preview: "Pré-visualizar",
      share: "Compartilhar",
      personalize: "Personalizar",
      view: "Visualizar",
      complete_your_profile: "Complete seu perfil",
      personal_details: "Detalhes pessoais",
      customize_content_experience: "Personalizar experiência de conteúdo",
      share_on_whatsapp: "Compartilhar no WhatsApp",
      language: "Idioma",
      ai_description: "Nosso assistente de IA está escrevendo algo só para você! Basta copiar, colar, editar e compartilhar.",
      ai_regenerate: "Tente algo diferente",
      copy_text_create_post: "Copiar texto e criar postagem",
      copy_link_to_content_experience: "Copiar link para sua experiência de conteúdo",
      email: "E-mail",
      qr_code: "Código QR",
      link: "Link",
      my_experiences: "Minhas experiências",
      my_profile: "Meu perfil",
      about_my_business: "Sobre meu negócio",
      i_am_a_travel_advisor: "Sou um agente de viagens/agência ou vendedor de terceiros similar",
      host_agency: "Agência anfitriã",
      agency_association_consortium: "Associação de agências / Consórcio",
      booking_email: "E-mail de reserva",
      booking_phone_number: "Número de telefone de reserva",
      iata_used_for_booking: "IATA usado para reserva",
      clia_used_for_booking: "CLIA usado para reserva",
      company_name: "Nome da empresa",
      business_languages: "Idiomas em que faço negócios",
      update: "Atualizar",
      saving: "Salvando",
      saved: "Salvo",
      logout: "Sair",
      cobrand_and_share: "Co-brandizar e compartilhar",
      your_content_experiences: "Suas experiências de conteúdo",
      your_experiences: "Suas experiências",
      new_experiences: "Novas experiências",
      all_content_experiences: "Todas as experiências de conteúdo",
      search: "Buscar",
      experiences: "Experiências",
      cobranded_website: "Site co-brandizado",
      about: "Sobre",
      your_partnerName_content_experiences: "Suas experiências de conteúdo :partnerName",
      content_experiences_description:
        "As experiências de conteúdo permitem que você entregue rapidamente conteúdo inspirador de uma maneira altamente personalizada. **Clique em uma experiência abaixo para começar.**",
      cobrand_the_partnerName_website: "Co-brandizar o site de :partnerName",
      cobrand_website_url_description:
        "Aqui está o seu site pessoal co-brandizado de :partnerName. Compartilhe esse link com seus clientes de forma segura - transformamos o site só para você.",
      copy_link: "Copiar link",
      copied: "Copiado",
      cobrand_website_any_url_description:
        "Fica ainda melhor! Você pode co-brandizar qualquer página no site de :partnerName. Simplesmente insira qualquer página do site de :partnerName para obter seu link co-brandizado.",
      cobrand: "Co-brandizar",
      contact: "Contato",
      for_travel_brands: "Para marcas de viagens",
      recommend: "Recomendar",
      recommend_us_to_suppliers: "Recomende-nos aos seus fornecedores favoritos",
      recommend_us_description:
        "Não seria ótimo se todos os seus fornecedores tornassem o marketing tão fácil? Avise-os de que eles podem criar experiências de conteúdo para impulsionar seu marketing. Clique no botão e deixe-nos criar um e-mail para você!",
      cancel: "Cancelar",
      copy_and_paste_in_email: "Copiar (e colar em um e-mail)",
      pagination: ":itemsFrom - :itemsTo de :itemsTotal itens.",
      choose_a_content_experience: "Escolha uma experiência de conteúdo",
      sign_in: "Entrar",
      get_started: "Começar",
      sign_in_to_account: "Entrar na sua conta",
      sign_in_description: "Digite seu e-mail e enviaremos um link que fará o login automaticamente.",
      email_address: "Endereço de e-mail",
      no_account: "Não tem uma conta?",
      sign_up_title: "Cadastre-se para começar a compartilhar experiências co-brandizadas",
      sign_up_description: "Em segundos, você pode co-brandizar e compartilhar nossas experiências de conteúdo para construir seu negócio.",
      create_account: "Criar conta",
      personalize_your_content_experience: "Personalize esta experiência de conteúdo",
      invitation_description:
        "Criamos essa experiência de conteúdo para impulsionar seu marketing e aumentar a conversão. É fácil personalizar—basta inserir seu e-mail para começar!",
      enter_email_legal: "Ao inserir seu e-mail, você concorda com os",
      and: "e",
      magic_link_email_sent_title: "Confira seu e-mail!",
      magic_link_email_sent_description: "Para começar, clique no botão do link mágico no e-mail que enviamos para **:userEmail**",
      magic_link_legal: "Ao clicar no link mágico, você concorda com os",
      preview_description: "Esta é uma pré-visualização. Para ver a experiência real do convidado, saia.",
      name: "Nome",
      logo_personal_photo: "Logo e Foto pessoal",
      location: "Localização",
      consortium: "Consórcio",
      booking_email_phone: "E-mail e telefone de reserva",
      home: "Início",
      update_profile: "Atualizar perfil",
      profile_instructions: "Por favor, confirme ou preencha os campos destacados.",
      select: "Selecionar",
      country: "País",
      state_region: "Estado / Região",
      homepage_title: "Potencialize seus esforços de marketing e vendas",
      homepage_subtitle: "com experiências de conteúdo co-brandizadas gratuitas",
      share_qr_code_description: "Baixe o código ou faça uma captura de tela para compartilhar ainda mais rápido.",
      download_qr_code: "Baixar código QR",
      bilbert_email_start: "Estou criando um e-mail, pessoalmente para você! Me dê alguns segundos",
      bilbert_email_end: "Tudo pronto! Clique no botão abaixo para copiar, e depois cole no e-mail e envie!",
      ai_robot: "Robô AI",
      sign_up: "Cadastrar",
      already_signed_up: "Já se cadastrou?",
      cookies_description: "Usamos cookies para melhorar sua experiência.",
      learn_more: "Saiba mais",
      accept: "Aceitar",
      hooray: "Viva!",
      experience_creation_description: "Nos dê um momento enquanto criamos sua experiência co-brandizada...",
      share_link_everywhere: "Compartilhe seu link em todos os lugares",
      sales_links_header: "Links para compartilhar com parceiros",
      sales_links_description:
        "Participando de uma reunião ou evento, fazendo um seminário web, enviando um e-mail? Este é um recurso completo e atualizado com tudo o que você precisa para convidar parceiros a co-brandizar e compartilhar suas experiências de conteúdo.",
      step_one_header: "Copie o link de convite ou baixe o código QR",
      step_one_description:
        "Copie o link de convite ou baixe o código QR para todas as experiências de conteúdo :partnerName, ou escolha uma experiência de conteúdo específica.",
      step_one_tip: "Não se esqueça de **testar** seu código QR antes de compartilhar!",
      copy_invite_link: "Copiar link de convite",
      copied_to_clipboard: "Copiado para a área de transferência",
      or_choose_content_experience: "Ou escolha uma experiência de conteúdo específica:",
      step_two_header: "Coloque em sua apresentação de webinar, e-mail e postagens nas redes sociais",
      step_two_description:
        "Coloque em sua apresentação de webinar, e-mail, postagens nas redes sociais... onde você estiver interagindo com parceiros comerciais.",
      step_three_header: "Adicione um texto explicativo",
      step_three_description: "Adicione um texto explicativo para ajudar os parceiros a entender por que você está compartilhando os links e códigos QR.",
      step_three_tip: "Não se esqueça de colar seu link e/ou código QR!",
      sales_example_one: "É a maneira mais fácil de vender :partnerName. Clique para começar!",
      sales_example_two: "Clique para co-brandizar e compartilhar instantaneamente nossa última experiência de conteúdo.",
      sales_example_three: "Clique para começar a vender :partnerName instantaneamente",
      just_a_moment: "Um momento",
      thats_strange: "Isso é estranho...",
      invalid_magic_link: "Este link mágico é inválido. Por favor, clique novamente no botão do link mágico ou cole a URL mágica associada",
      oops: "Ops!",
      magic_link_used: "Links mágicos são para uso único e este já foi usado. Por favor, insira novamente seu e-mail para receber um novo link mágico.",
      get_new_magic_link: "Obtenha seu novo link mágico!",
      new_magic_link_sent: "Seu link mágico expirou, então enviamos um novo para você.",
      magic_link_check_email: "Verifique seu e-mail e clique para fazer login",
      cobrand_our_website: "Co-brandiza nosso site",
      required_field: "Este campo é obrigatório.",
      sales_example_description: "Aqui estão alguns exemplos para começar:",
      confirm: "Confirmar",
    },
    fr: {
      first_name: "Prénom",
      last_name: "Nom",
      phone_number: "Numéro de téléphone",
      logo: "Logo",
      personal_photo: "Photo personnelle",
      personal_message: "Message personnel",
      call_to_action_button: "Bouton d'appel à l'action",
      button_text: "Texte du bouton",
      none: "Aucun",
      preset: "Prédéfinir",
      custom: "Personnalisé",
      preview: "Aperçu",
      share: "Partager",
      personalize: "Personnaliser",
      view: "Voir",
      complete_your_profile: "Complétez votre profil",
      personal_details: "Détails personnels",
      customize_content_experience: "Personnaliser l'expérience de contenu",
      share_on_whatsapp: "Partager sur WhatsApp",
      language: "Langue",
      ai_description: "Notre assistant AI écrit quelque chose juste pour vous! Il suffit de copier, coller, éditer et partager.",
      ai_regenerate: "Essayez quelque chose de différent",
      copy_text_create_post: "Copier le texte et créer un post",
      copy_link_to_content_experience: "Copier le lien de votre expérience de contenu",
      email: "E-mail",
      qr_code: "Code QR",
      link: "Lien",
      my_experiences: "Mes expériences",
      my_profile: "Mon profil",
      about_my_business: "À propos de mon entreprise",
      i_am_a_travel_advisor: "Je suis un agent de voyage/une agence ou un vendeur tiers similaire",
      host_agency: "Agence hôte",
      agency_association_consortium: "Association d'agences / Consortium",
      booking_email: "E-mail de réservation",
      booking_phone_number: "Numéro de téléphone de réservation",
      iata_used_for_booking: "IATA utilisé pour les réservations",
      clia_used_for_booking: "CLIA utilisé pour les réservations",
      company_name: "Nom de l'entreprise",
      business_languages: "Langues dans lesquelles je mène des affaires",
      update: "Mettre à jour",
      saving: "Sauvegarde",
      saved: "Enregistré",
      logout: "Se déconnecter",
      cobrand_and_share: "Co-marquer et partager",
      your_content_experiences: "Vos expériences de contenu",
      your_experiences: "Vos expériences",
      new_experiences: "Nouvelles expériences",
      all_content_experiences: "Toutes les expériences de contenu",
      search: "Rechercher",
      experiences: "Expériences",
      cobranded_website: "Site Web co-marqué",
      about: "À propos",
      your_partnerName_content_experiences: "Vos expériences de contenu :partnerName",
      content_experiences_description:
        "Les expériences de contenu vous permettent de livrer rapidement du contenu inspirant de manière très personnalisée. **Cliquez sur une expérience ci-dessous pour commencer.**",
      cobrand_the_partnerName_website: "Co-marquer le site Web de :partnerName",
      cobrand_website_url_description:
        "Voici votre site personnel co-marqué de :partnerName. Partagez ce lien en toute sécurité avec vos clients - nous avons transformé le site juste pour vous.",
      copy_link: "Copier le lien",
      copied: "Copié",
      cobrand_website_any_url_description:
        "C'est encore mieux! Vous pouvez co-marquer n'importe quelle page du site Web de :partnerName. Il vous suffit de saisir n'importe quelle page Web de :partnerName pour obtenir votre lien co-marqué.",
      cobrand: "Co-marquer",
      contact: "Contact",
      for_travel_brands: "Pour les marques de voyage",
      recommend: "Recommander",
      recommend_us_to_suppliers: "Recommandez-nous à vos fournisseurs préférés",
      recommend_us_description:
        "Vous ne souhaitez pas que tous vos fournisseurs rendent le marketing aussi facile? Faites-leur savoir qu'ils peuvent créer des expériences de contenu pour alimenter votre marketing. Cliquez sur le bouton et laissez-nous rédiger un e-mail pour vous!",
      cancel: "Annuler",
      copy_and_paste_in_email: "Copier (et coller dans un e-mail)",
      pagination: ":itemsFrom - :itemsTo de :itemsTotal articles.",
      choose_a_content_experience: "Choisir une expérience de contenu",
      sign_in: "Se connecter",
      get_started: "Commencer",
      sign_in_to_account: "Se connecter à votre compte",
      sign_in_description: "Entrez votre e-mail et nous vous enverrons un lien qui vous connectera automatiquement.",
      email_address: "Adresse e-mail",
      no_account: "Pas de compte?",
      sign_up_title: "Inscrivez-vous pour commencer à partager des expériences co-marqué",
      sign_up_description: "En quelques secondes, vous pouvez co-marquer et partager nos expériences de contenu pour développer votre entreprise.",
      create_account: "Créer un compte",
      personalize_your_content_experience: "Personnaliser cette expérience de contenu",
      invitation_description:
        "Nous avons conçu cette expérience de contenu pour alimenter votre marketing et améliorer votre conversion. C'est facile à personnaliser - il vous suffit d'entrer votre e-mail pour commencer!",
      enter_email_legal: "En entrant votre adresse e-mail, vous acceptez les",
      and: "et",
      magic_link_email_sent_title: "Vérifiez votre e-mail!",
      magic_link_email_sent_description: "Pour commencer, cliquez sur le bouton du lien magique dans l'e-mail que nous avons envoyé à **:userEmail**",
      magic_link_legal: "En cliquant sur le lien magique, vous acceptez les",
      preview_description: "Ceci est un aperçu. Pour voir l'expérience réelle du client, déconnectez-vous.",
      name: "Nom",
      logo_personal_photo: "Logo et photo personnelle",
      location: "Emplacement",
      consortium: "Consortium",
      booking_email_phone: "E-mail et téléphone de réservation",
      home: "Accueil",
      update_profile: "Mettre à jour le profil",
      profile_instructions: "Veuillez confirmer ou remplir les champs surlignés.",
      select: "Sélectionner",
      country: "Pays",
      state_region: "État / Région",
      homepage_title: "Boostez vos efforts marketing et commerciaux",
      homepage_subtitle: "avec des expériences de contenu co-marqué gratuites",
      share_qr_code_description: "Téléchargez le code ou prenez une capture d'écran pour le partager encore plus rapidement.",
      download_qr_code: "Télécharger le code QR",
      bilbert_email_start: "Je suis en train de rédiger un e-mail, personnellement pour vous! Donnez-moi quelques secondes",
      bilbert_email_end: "C'est fait! Cliquez simplement sur le bouton ci-dessous pour le copier, puis collez-le dans un e-mail et envoyez-le!",
      ai_robot: "Robot AI",
      sign_up: "S'inscrire",
      already_signed_up: "Déjà inscrit?",
      cookies_description: "Nous utilisons des cookies pour améliorer votre expérience.",
      learn_more: "En savoir plus",
      accept: "Accepter",
      hooray: "Hourra!",
      experience_creation_description: "Donnez-nous un moment pendant que nous créons votre expérience co-marqué...",
      share_link_everywhere: "Partagez votre lien partout",
      sales_links_header: "Liens à partager avec des partenaires",
      sales_links_description:
        "Vous assistez à une réunion ou un événement, faites un webinaire ou envoyez un e-mail? C'est une ressource complète et à jour avec tout ce dont vous avez besoin pour inviter vos partenaires à co-marquer et partager vos expériences de contenu.",
      step_one_header: "Copiez le lien d'invitation ou téléchargez le code QR",
      step_one_description:
        "Copiez le lien d'invitation ou téléchargez le code QR pour toutes les expériences de contenu :partnerName, ou choisissez une expérience de contenu spécifique.",
      step_one_tip: "N'oubliez pas de **tester** votre code QR avant de le partager!",
      copy_invite_link: "Copier le lien d'invitation",
      copied_to_clipboard: "Copié dans le presse-papiers",
      or_choose_content_experience: "Ou choisissez une expérience de contenu spécifique:",
      step_two_header: "Mettez-le dans votre présentation de webinaire, votre e-mail et vos posts sociaux",
      step_two_description:
        "Mettez-le dans votre présentation de webinaire, votre e-mail, votre publication sur les réseaux sociaux... où que vous interagissiez avec des partenaires commerciaux.",
      step_three_header: "Ajoutez un texte explicatif",
      step_three_description: "Ajoutez un texte explicatif pour aider les partenaires à comprendre pourquoi vous partagez les liens et les codes QR.",
      step_three_tip: "N'oubliez pas de coller votre lien et/ou code QR!",
      sales_example_one: "C'est la façon la plus simple de vendre :partnerName. Cliquez pour commencer!",
      sales_example_two: "Cliquez pour co-marquer et partager instantanément notre dernière expérience de contenu.",
      sales_example_three: "Cliquez pour commencer à vendre :partnerName instantanément",
      just_a_moment: "Un instant",
      thats_strange: "C'est étrange...",
      invalid_magic_link: "Ce lien magique est invalide. Veuillez cliquer à nouveau sur le bouton du lien magique ou coller l'URL magique associée",
      oops: "Oups!",
      magic_link_used:
        "Les liens magiques sont pour une utilisation unique et celui-ci a déjà été utilisé. Veuillez entrer à nouveau votre e-mail pour recevoir un nouveau lien magique.",
      get_new_magic_link: "Obtenez votre nouveau lien magique!",
      new_magic_link_sent: "Votre lien magique a expiré, nous vous avons donc envoyé un nouveau.",
      magic_link_check_email: "Vérifiez votre e-mail et cliquez pour vous connecter",
      cobrand_our_website: "Co-marquer notre site web",
      required_field: "Ce champ est requis.",
      sales_example_description: "Voici quelques exemples pour vous aider à démarrer:",
      confirm: "Confirmer",
    },
    de: {
      first_name: "Vorname",
      last_name: "Nachname",
      phone_number: "Telefonnummer",
      logo: "Logo",
      personal_photo: "Persönliches Foto",
      personal_message: "Persönliche Nachricht",
      call_to_action_button: "Call-to-Action-Schaltfläche",
      button_text: "Schaltflächentext",
      none: "Keine",
      preset: "Vorgabe",
      custom: "Benutzerdefiniert",
      preview: "Vorschau",
      share: "Teilen",
      personalize: "Personalisieren",
      view: "Ansehen",
      complete_your_profile: "Vervollständigen Sie Ihr Profil",
      personal_details: "Persönliche Details",
      customize_content_experience: "Inhaltserfahrung anpassen",
      share_on_whatsapp: "Auf WhatsApp teilen",
      language: "Sprache",
      ai_description: "Unser KI-Assistent schreibt gerade etwas nur für Sie! Einfach kopieren, einfügen, bearbeiten und teilen.",
      ai_regenerate: "Versuchen Sie etwas anderes",
      copy_text_create_post: "Text kopieren & Beitrag erstellen",
      copy_link_to_content_experience: "Link zu Ihrer Inhaltserfahrung kopieren",
      email: "E-Mail",
      qr_code: "QR-Code",
      link: "Link",
      my_experiences: "Meine Erfahrungen",
      my_profile: "Mein Profil",
      about_my_business: "Über mein Unternehmen",
      i_am_a_travel_advisor: "Ich bin ein Reiseberater/Reiseagentur oder ein ähnlicher Drittanbieter von Reisen",
      host_agency: "Host-Agentur",
      agency_association_consortium: "Agenturverband / Konsortium",
      booking_email: "Buchungs-E-Mail",
      booking_phone_number: "Buchungs-Telefonnummer",
      iata_used_for_booking: "IATA für Buchungen verwendet",
      clia_used_for_booking: "CLIA für Buchungen verwendet",
      company_name: "Firmenname",
      business_languages: "Sprachen, in denen ich Geschäfte mache",
      update: "Aktualisieren",
      saving: "Speichern",
      saved: "Gespeichert",
      logout: "Abmelden",
      cobrand_and_share: "Co-brand und teilen",
      your_content_experiences: "Ihre Inhaltserfahrungen",
      your_experiences: "Ihre Erfahrungen",
      new_experiences: "Neue Erfahrungen",
      all_content_experiences: "Alle Inhaltserfahrungen",
      search: "Suche",
      experiences: "Erfahrungen",
      cobranded_website: "Co-branding Website",
      about: "Über",
      your_partnerName_content_experiences: "Ihre :partnerName Inhaltserfahrungen",
      content_experiences_description:
        "Inhaltserfahrungen ermöglichen es Ihnen, schnell inspirierende Inhalte auf eine hochgradig personalisierte Weise zu liefern. **Klicken Sie auf eine Erfahrung unten, um zu starten.**",
      cobrand_the_partnerName_website: "Co-brand die :partnerName Website",
      cobrand_website_url_description:
        "Hier ist Ihre persönliche, co-branding :partnerName Website. Teilen Sie diesen Link sicher mit Ihren Kunden – wir haben die Seite nur für Sie umgestaltet.",
      copy_link: "Link kopieren",
      copied: "Kopiert",
      cobrand_website_any_url_description:
        "Es wird noch besser! Sie können jede Seite auf der :partnerName Website co-branding. Geben Sie einfach eine beliebige :partnerName Webseite ein, um Ihren co-branding Link zu erhalten.",
      cobrand: "Co-branding",
      contact: "Kontakt",
      for_travel_brands: "Für Reiseunternehmen",
      recommend: "Empfehlen",
      recommend_us_to_suppliers: "Empfehlen Sie uns Ihren bevorzugten Lieferanten",
      recommend_us_description:
        "Möchten Sie nicht, dass alle Ihre Lieferanten Marketing so einfach machen? Lassen Sie Ihre Lieferanten wissen, dass sie Inhaltserfahrungen erstellen können, um Ihr Marketing zu unterstützen. Klicken Sie auf den Button und lassen Sie uns eine E-Mail für Sie erstellen!",
      cancel: "Abbrechen",
      copy_and_paste_in_email: "Kopieren (und in eine E-Mail einfügen)",
      pagination: ":itemsFrom - :itemsTo von :itemsTotal Artikeln.",
      choose_a_content_experience: "Wählen Sie eine Inhaltserfahrung",
      sign_in: "Anmelden",
      get_started: "Loslegen",
      sign_in_to_account: "Melden Sie sich bei Ihrem Konto an",
      sign_in_description: "Geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen einen Link, der Sie automatisch anmeldet.",
      email_address: "E-Mail-Adresse",
      no_account: "Kein Konto?",
      sign_up_title: "Melden Sie sich an, um co-branded Erfahrungen zu teilen",
      sign_up_description: "In wenigen Sekunden können Sie unsere Inhaltserfahrungen co-branding und teilen, um Ihr Geschäft auszubauen.",
      create_account: "Konto erstellen",
      personalize_your_content_experience: "Personalisieren Sie diese Inhaltserfahrung",
      invitation_description:
        "Wir haben diese Inhaltserfahrung entwickelt, um Ihr Marketing zu unterstützen und die Konversion zu steigern. Es ist einfach anzupassen – geben Sie einfach Ihre E-Mail-Adresse ein, um zu beginnen!",
      enter_email_legal: "Durch die Eingabe Ihrer E-Mail-Adresse stimmen Sie den",
      and: "und",
      magic_link_email_sent_title: "Überprüfen Sie Ihre E-Mail!",
      magic_link_email_sent_description: "Um zu starten, klicken Sie auf den Magic-Link Button in der E-Mail, die wir an **:userEmail** gesendet haben",
      magic_link_legal: "Durch Klicken auf den Magic-Link stimmen Sie den",
      preview_description: "Dies ist eine Vorschau. Um die tatsächliche Gästeerfahrung zu sehen, melden Sie sich ab.",
      name: "Name",
      logo_personal_photo: "Logo und Persönliches Foto",
      location: "Standort",
      consortium: "Konsortium",
      booking_email_phone: "Buchungs-E-Mail und Telefon",
      home: "Startseite",
      update_profile: "Profil aktualisieren",
      profile_instructions: "Bestätigen oder füllen Sie bitte die hervorgehobenen Felder aus.",
      select: "Auswählen",
      country: "Land",
      state_region: "Bundesland / Region",
      homepage_title: "Steigern Sie Ihre Marketing- und Verkaufsbemühungen",
      homepage_subtitle: "mit kostenlosen Co-Branding Inhaltserfahrungen",
      share_qr_code_description: "Laden Sie den Code herunter oder machen Sie einen Screenshot, um noch schneller zu teilen.",
      download_qr_code: "QR-Code herunterladen",
      bilbert_email_start: "Ich erstelle eine E-Mail, persönlich für Sie! Geben Sie mir ein paar Sekunden",
      bilbert_email_end: "Fertig! Klicken Sie einfach auf den Button unten, um es zu kopieren, und fügen Sie es dann in eine E-Mail ein und senden Sie es!",
      ai_robot: "KI-Roboter",
      sign_up: "Anmelden",
      already_signed_up: "Bereits angemeldet?",
      cookies_description: "Wir verwenden Cookies, um Ihre Erfahrung zu verbessern.",
      learn_more: "Mehr erfahren",
      accept: "Akzeptieren",
      hooray: "Hurra!",
      experience_creation_description: "Geben Sie uns einen Moment, während wir Ihre Co-Branding-Erfahrung erstellen...",
      share_link_everywhere: "Teilen Sie Ihren Link überall",
      sales_links_header: "Links zum Teilen mit Partnern",
      sales_links_description:
        "Nehmen Sie an einem Meeting oder Event teil, veranstalten Sie ein Webinar oder senden Sie eine E-Mail? Dies ist eine vollständige, aktuelle Ressource mit allem, was Sie benötigen, um Partner zum Co-Branding einzuladen und Ihre Inhaltserfahrungen zu teilen.",
      step_one_header: "Kopieren Sie den Einladung-Link oder laden Sie den QR-Code herunter",
      step_one_description:
        "Kopieren Sie den Einladung-Link oder laden Sie den QR-Code für alle :partnerName Inhaltserfahrungen herunter oder wählen Sie eine spezifische Inhaltserfahrung aus.",
      step_one_tip: "Vergessen Sie nicht, Ihren QR-Code vor dem Teilen **zu testen**!",
      copy_invite_link: "Einladung-Link kopieren",
      copied_to_clipboard: "In die Zwischenablage kopiert",
      or_choose_content_experience: "Oder wählen Sie eine spezifische Inhaltserfahrung aus:",
      step_two_header: "Fügen Sie es in Ihre Webinar-Präsentation, E-Mail und Social-Media-Posts ein",
      step_two_description:
        "Fügen Sie es in Ihre Webinar-Präsentation, E-Mail, Social-Media-Post ... überall dort ein, wo Sie mit Partnern im Handel interagieren.",
      step_three_header: "Fügen Sie erklärenden Text hinzu",
      step_three_description: "Fügen Sie erklärenden Text hinzu, um Partnern zu helfen, zu verstehen, warum Sie die Links und QR-Codes teilen.",
      step_three_tip: "Vergessen Sie nicht, Ihren Link und/oder QR-Code einzufügen!",
      sales_example_one: "Es ist der einfachste Weg, :partnerName zu verkaufen. Klicken Sie, um zu starten!",
      sales_example_two: "Klicken Sie, um sofort zu Co-Branding und teilen Sie unsere neueste Inhaltserfahrung.",
      sales_example_three: "Klicken Sie, um sofort mit dem Verkauf von :partnerName zu beginnen",
      just_a_moment: "Einen Moment",
      thats_strange: "Das ist seltsam...",
      invalid_magic_link: "Dieser magische Link ist ungültig. Klicken Sie erneut auf den magischen Link-Button oder fügen Sie die zugehörige magische URL ein",
      oops: "Ups!",
      magic_link_used:
        "Magische Links sind nur einmalig verwendbar und dieser wurde bereits verwendet. Bitte geben Sie erneut Ihre E-Mail-Adresse ein, um einen neuen magischen Link zu erhalten.",
      get_new_magic_link: "Holen Sie sich Ihren neuen magischen Link!",
      new_magic_link_sent: "Ihr magischer Link ist abgelaufen, daher haben wir Ihnen einen neuen geschickt.",
      magic_link_check_email: "Überprüfen Sie Ihre E-Mail und klicken Sie, um sich anzumelden",
      cobrand_our_website: "Co-branden Sie unsere Website",
      required_field: "Dieses Feld ist erforderlich.",
      sales_example_description: "Hier sind einige Beispiele, um Ihnen den Einstieg zu erleichtern:",
      confirm: "Bestätigen",
    },
    zh: {
      first_name: "名字",
      last_name: "姓氏",
      phone_number: "电话号码",
      logo: "标志",
      personal_photo: "个人照片",
      personal_message: "个人信息",
      call_to_action_button: "号召性按钮",
      button_text: "按钮文本",
      none: "无",
      preset: "预设",
      custom: "自定义",
      preview: "预览",
      share: "分享",
      personalize: "个性化",
      view: "查看",
      complete_your_profile: "完成您的个人资料",
      personal_details: "个人资料",
      customize_content_experience: "自定义内容体验",
      share_on_whatsapp: "通过 WhatsApp 分享",
      language: "语言",
      ai_description: "我们的 AI 助手正在为您编写内容！只需复制、粘贴、编辑并分享。",
      ai_regenerate: "尝试不同的内容",
      copy_text_create_post: "复制文本并创建帖子",
      copy_link_to_content_experience: "复制内容体验链接",
      email: "电子邮件",
      qr_code: "二维码",
      link: "链接",
      my_experiences: "我的体验",
      my_profile: "我的个人资料",
      about_my_business: "关于我的业务",
      i_am_a_travel_advisor: "我是一名旅行顾问/旅行社或类似的第三方旅行销售商",
      host_agency: "主办机构",
      agency_association_consortium: "代理机构协会/联合体",
      booking_email: "预订电子邮件",
      booking_phone_number: "预订电话号码",
      iata_used_for_booking: "用于预订的 IATA",
      clia_used_for_booking: "用于预订的 CLIA",
      company_name: "公司名称",
      business_languages: "我开展业务的语言",
      update: "更新",
      saving: "保存",
      saved: "已保存",
      logout: "登出",
      cobrand_and_share: "共同品牌并分享",
      your_content_experiences: "您的内容体验",
      your_experiences: "您的体验",
      new_experiences: "新体验",
      all_content_experiences: "所有内容体验",
      search: "搜索",
      experiences: "体验",
      cobranded_website: "共同品牌网站",
      about: "关于",
      your_partnerName_content_experiences: "您的 :partnerName 内容体验",
      content_experiences_description: "内容体验使您能够以高度个性化的方式快速提供启发性内容。**点击下面的体验开始。**",
      cobrand_the_partnerName_website: "共同品牌 :partnerName 网站",
      cobrand_website_url_description: "这是您的个人共同品牌 :partnerName 网站。安全地与客户分享此链接——我们为您专门定制了该站点。",
      copy_link: "复制链接",
      copied: "已复制",
      cobrand_website_any_url_description:
        "更棒的是！您可以共同品牌 :partnerName 网站上的任何页面。只需输入任何 :partnerName 网页地址，即可获取您的共同品牌链接。",
      cobrand: "共同品牌",
      contact: "联系方式",
      for_travel_brands: "旅行品牌专用",
      recommend: "推荐",
      recommend_us_to_suppliers: "向您喜欢的供应商推荐我们",
      recommend_us_description:
        "难道你不希望所有的供应商都能轻松地做营销吗？让他们知道他们可以创建内容体验来助力您的营销。点击按钮，我们将为您撰写一封电子邮件！",
      cancel: "取消",
      copy_and_paste_in_email: "复制（并粘贴到电子邮件中）",
      pagination: ":itemsFrom - :itemsTo，共 :itemsTotal 项。",
      choose_a_content_experience: "选择内容体验",
      sign_in: "登录",
      get_started: "开始",
      sign_in_to_account: "登录您的帐户",
      sign_in_description: "输入您的电子邮件，我们将发送一个链接，自动登录。",
      email_address: "电子邮件地址",
      no_account: "没有帐户？",
      sign_up_title: "注册以开始分享共同品牌体验",
      sign_up_description: "只需几秒钟，您就可以共同品牌并分享我们的内容体验来扩展您的业务。",
      create_account: "创建账户",
      personalize_your_content_experience: "个性化此内容体验",
      invitation_description: "我们创建了此内容体验来助力您的营销并推动转化。很容易定制——只需输入您的电子邮件即可开始！",
      enter_email_legal: "通过输入您的电子邮件，您同意",
      and: "和",
      magic_link_email_sent_title: "检查您的电子邮件！",
      magic_link_email_sent_description: "要开始，请点击我们发送到 **:userEmail** 的电子邮件中的魔法链接按钮",
      magic_link_legal: "点击魔法链接即表示您同意",
      preview_description: "这是预览。要查看实际的客户体验，请退出。",
      name: "姓名",
      logo_personal_photo: "标志与个人照片",
      location: "位置",
      consortium: "联盟",
      booking_email_phone: "预订电子邮件和电话",
      home: "首页",
      update_profile: "更新个人资料",
      profile_instructions: "请确认或填写高亮显示的字段。",
      select: "选择",
      country: "国家",
      state_region: "州/地区",
      homepage_title: "增强您的营销和销售工作",
      homepage_subtitle: "通过免费的共同品牌内容体验",
      share_qr_code_description: "下载代码或截图以更快速地分享。",
      download_qr_code: "下载二维码",
      bilbert_email_start: "我正在为您亲自撰写电子邮件！请稍等片刻",
      bilbert_email_end: "完成！只需点击下面的按钮复制，然后粘贴到电子邮件中并发送！",
      ai_robot: "AI 机器人",
      sign_up: "注册",
      already_signed_up: "已经注册？",
      cookies_description: "我们使用 cookies 来改善您的体验。",
      learn_more: "了解更多",
      accept: "接受",
      hooray: "好极了！",
      experience_creation_description: "请稍等片刻，我们正在创建您的共同品牌体验...",
      share_link_everywhere: "到处分享您的链接",
      sales_links_header: "与合作伙伴共享的链接",
      sales_links_description:
        "参加会议或活动、做网络研讨会、发送电子邮件？这是一个完整的、最新的资源，包含了您邀请合作伙伴共同品牌和分享您的内容体验所需的所有信息。",
      step_one_header: "复制邀请链接或下载二维码",
      step_one_description: "复制邀请链接或下载二维码，适用于所有 :partnerName 内容体验，或选择特定的内容体验。",
      step_one_tip: "在分享之前别忘了 **测试** 您的二维码！",
      copy_invite_link: "复制邀请链接",
      copied_to_clipboard: "已复制到剪贴板",
      or_choose_content_experience: "或选择特定的内容体验：",
      step_two_header: "将其放入您的网络研讨会演示、电子邮件和社交帖子中",
      step_two_description: "将其放入您的网络研讨会演示、电子邮件、社交帖子中……无论您在哪里与合作伙伴互动。",
      step_three_header: "添加解释性文本",
      step_three_description: "添加解释性文本，帮助合作伙伴理解您为什么要分享这些链接和二维码。",
      step_three_tip: "别忘了粘贴您的链接和/或二维码！",
      sales_example_one: "这是卖 :partnerName 最简单的方法。点击开始！",
      sales_example_two: "点击立即共同品牌并分享我们最新的内容体验。",
      sales_example_three: "点击立即开始销售 :partnerName",
      just_a_moment: "稍等",
      thats_strange: "这很奇怪...",
      invalid_magic_link: "这个魔法链接无效。请重新点击魔法链接按钮或粘贴关联的魔法 URL",
      oops: "哎呀！",
      magic_link_used: "魔法链接是一次性使用的，且此链接已经被使用。请重新输入您的电子邮件地址以接收新的魔法链接。",
      get_new_magic_link: "获取您的新魔法链接！",
      new_magic_link_sent: "您的魔法链接已过期，我们已为您发送了新的链接。",
      magic_link_check_email: "检查您的电子邮件并点击登录",
      cobrand_our_website: "共同品牌我们的站点",
      required_field: "此字段为必填。",
      sales_example_description: "以下是一些示例，帮助您开始：",
      confirm: "确认",
    },
    nl: {
      first_name: "Voornaam",
      last_name: "Achternaam",
      phone_number: "Telefoonnummer",
      logo: "Logo",
      personal_photo: "Persoonlijke foto",
      personal_message: "Persoonlijk bericht",
      call_to_action_button: "Oproep tot actie knop",
      button_text: "Knoptekst",
      none: "Geen",
      preset: "Standaard",
      custom: "Aangepast",
      preview: "Voorbeeld",
      share: "Deel",
      personalize: "Personaliseer",
      view: "Bekijk",
      complete_your_profile: "Voltooi je profiel",
      personal_details: "Persoonlijke gegevens",
      customize_content_experience: "Pas de inhoudservaring aan",
      share_on_whatsapp: "Deel op WhatsApp",
      language: "Taal",
      ai_description: "Onze AI-assistent schrijft iets speciaal voor jou! Kopieer, plak, bewerk en deel eenvoudig.",
      ai_regenerate: "Probeer iets anders",
      copy_text_create_post: "Kopieer tekst en maak bericht",
      copy_link_to_content_experience: "Kopieer link naar je inhoudservaring",
      email: "E-mail",
      qr_code: "QR-code",
      link: "Link",
      my_experiences: "Mijn ervaringen",
      my_profile: "Mijn profiel",
      about_my_business: "Over mijn bedrijf",
      i_am_a_travel_advisor: "Ik ben een reisadviseur/reisbureau of een vergelijkbare derde partij voor reisverkoop",
      host_agency: "Gastbureau",
      agency_association_consortium: "Bureauvereniging / Consortium",
      booking_email: "Boekings-e-mail",
      booking_phone_number: "Boekings-telefoonnummer",
      iata_used_for_booking: "IATA gebruikt voor boekingen",
      clia_used_for_booking: "CLIA gebruikt voor boekingen",
      company_name: "Bedrijfsnaam",
      business_languages: "Talen waarin ik zakendoe",
      update: "Bijwerken",
      saving: "Opslaan",
      saved: "Opgeslagen",
      logout: "Uitloggen",
      cobrand_and_share: "Co-brand en deel",
      your_content_experiences: "Je inhoudservaringen",
      your_experiences: "Je ervaringen",
      new_experiences: "Nieuwe ervaringen",
      all_content_experiences: "Alle inhoudservaringen",
      search: "Zoeken",
      experiences: "Ervaringen",
      cobranded_website: "Co-branding website",
      about: "Over",
      your_partnerName_content_experiences: "Je :partnerName inhoudservaringen",
      content_experiences_description:
        "Inhoudservaringen stellen je in staat om snel inspirerende inhoud op een zeer gepersonaliseerde manier te leveren. **Klik op een ervaring hieronder om te beginnen.**",
      cobrand_the_partnerName_website: "Co-brand de :partnerName website",
      cobrand_website_url_description:
        "Hier is je persoonlijke co-branding :partnerName website. Deel deze link veilig met je klanten - we hebben de site speciaal voor jou aangepast.",
      copy_link: "Kopieer link",
      copied: "Gekopieerd",
      cobrand_website_any_url_description:
        "Het wordt nog beter! Je kunt elke pagina op de :partnerName website co-branding. Voer gewoon een willekeurige :partnerName webpagina in om je co-branding link te krijgen.",
      cobrand: "Co-brand",
      contact: "Contact",
      for_travel_brands: "Voor reismerken",
      recommend: "Aanbevelen",
      recommend_us_to_suppliers: "Raad ons aan bij je favoriete leveranciers",
      recommend_us_description:
        "Zou het niet geweldig zijn als al je leveranciers marketing zo eenvoudig maakten? Laat je leveranciers weten dat ze inhoudservaringen kunnen maken om je marketing te ondersteunen. Klik op de knop en laat ons een e-mail voor je opstellen!",
      cancel: "Annuleren",
      copy_and_paste_in_email: "Kopieer (en plak in een e-mail)",
      pagination: ":itemsFrom - :itemsTo van :itemsTotal artikelen.",
      choose_a_content_experience: "Kies een inhoudservaring",
      sign_in: "Inloggen",
      get_started: "Begin",
      sign_in_to_account: "Log in op je account",
      sign_in_description: "Voer je e-mailadres in en we sturen je een link die je automatisch inlogt.",
      email_address: "E-mailadres",
      no_account: "Geen account?",
      sign_up_title: "Meld je aan om co-branding ervaringen te delen",
      sign_up_description: "Binnen enkele seconden kun je onze inhoudservaringen co-branding en delen om je bedrijf op te bouwen.",
      create_account: "Account aanmaken",
      personalize_your_content_experience: "Personaliseer deze inhoudservaring",
      invitation_description:
        "We hebben deze inhoudservaring gecreëerd om je marketing te ondersteunen en conversie te stimuleren. Het is gemakkelijk aan te passen - voer gewoon je e-mail in om te beginnen!",
      enter_email_legal: "Door je e-mail in te voeren, ga je akkoord met de",
      and: "en",
      magic_link_email_sent_title: "Bekijk je e-mail!",
      magic_link_email_sent_description: "Om te beginnen, klik op de magische linkknop in de e-mail die we naar **:userEmail** hebben gestuurd",
      magic_link_legal: "Door op de magische link te klikken, ga je akkoord met de",
      preview_description: "Dit is een voorbeeld. Om de werkelijke gastervaring te zien, log uit.",
      name: "Naam",
      logo_personal_photo: "Logo en persoonlijke foto",
      location: "Locatie",
      consortium: "Consortium",
      booking_email_phone: "Boekings-e-mail en telefoon",
      home: "Startpagina",
      update_profile: "Werk profiel bij",
      profile_instructions: "Bevestig of vul de gemarkeerde velden in.",
      select: "Selecteren",
      country: "Land",
      state_region: "Staat / Regio",
      homepage_title: "Versterk je marketing- en verkoopinspanningen",
      homepage_subtitle: "met gratis co-branding inhoudservaringen",
      share_qr_code_description: "Download de code of maak een screenshot voor nog snellere deling.",
      download_qr_code: "Download QR-code",
      bilbert_email_start: "Ik ben een e-mail aan het opstellen, persoonlijk voor jou! Geef me even een paar seconden",
      bilbert_email_end: "Klaar! Klik gewoon op de knop hieronder om te kopiëren, plak het dan in een e-mail en verstuur het!",
      ai_robot: "AI Robot",
      sign_up: "Aanmelden",
      already_signed_up: "Al geregistreerd?",
      cookies_description: "We gebruiken cookies om je ervaring te verbeteren.",
      learn_more: "Meer leren",
      accept: "Accepteren",
      hooray: "Hoera!",
      experience_creation_description: "Geef ons even de tijd om je co-branding ervaring te creëren...",
      share_link_everywhere: "Deel je link overal",
      sales_links_header: "Links om met partners te delen",
      sales_links_description:
        "Neem deel aan een vergadering of evenement, organiseer een webinar, verstuur een e-mail? Dit is een complete, up-to-date bron met alles wat je nodig hebt om partners uit te nodigen om je inhoudservaringen te co-branding en te delen.",
      step_one_header: "Kopieer de uitnodigingslink of download de QR-code",
      step_one_description:
        "Kopieer de uitnodigingslink of download de QR-code voor alle :partnerName inhoudservaringen, of kies een specifieke inhoudservaring.",
      step_one_tip: "Vergeet niet om je QR-code **te testen** voordat je deze deelt!",
      copy_invite_link: "Kopieer uitnodigingslink",
      copied_to_clipboard: "Gekopieerd naar klembord",
      or_choose_content_experience: "Of kies een specifieke inhoudservaring:",
      step_two_header: "Voeg het toe aan je webinarpresentatie, e-mail en sociale berichten",
      step_two_description: "Voeg het toe aan je webinarpresentatie, e-mail, social post... waar je ook interactie hebt met zakelijke partners.",
      step_three_header: "Voeg een verklarende tekst toe",
      step_three_description: "Voeg een verklarende tekst toe om partners te helpen begrijpen waarom je de links en QR-codes deelt.",
      step_three_tip: "Vergeet niet je link en/of QR-code in te voegen!",
      sales_example_one: "Het is de makkelijkste manier om :partnerName te verkopen. Klik om te starten!",
      sales_example_two: "Klik om direct co-branding en onze nieuwste inhoudservaring te delen.",
      sales_example_three: "Klik om direct te beginnen met het verkopen van :partnerName",
      just_a_moment: "Even geduld",
      thats_strange: "Dat is vreemd...",
      invalid_magic_link: "Deze magische link is ongeldig. Klik opnieuw op de magische linkknop of plak de bijbehorende magische URL",
      oops: "Oeps!",
      magic_link_used:
        "Magische links zijn voor eenmalig gebruik en deze is al gebruikt. Voer je e-mailadres opnieuw in om een nieuwe magische link te ontvangen.",
      get_new_magic_link: "Krijg je nieuwe magische link!",
      new_magic_link_sent: "Je magische link is verlopen, dus we hebben je een nieuwe gestuurd.",
      magic_link_check_email: "Controleer je e-mail en klik om in te loggen",
      cobrand_our_website: "Co-brand ons website",
      required_field: "Dit veld is verplicht.",
      sales_example_description: "Hier zijn enkele voorbeelden om je op weg te helpen:",
      confirm: "Bevestigen",
    },
    sv: {
      first_name: "Förnamn",
      last_name: "Efternamn",
      phone_number: "Telefonnummer",
      logo: "Logotyp",
      personal_photo: "Personligt foto",
      personal_message: "Personligt meddelande",
      call_to_action_button: "Call-to-action knapp",
      button_text: "Knapptext",
      none: "Inget",
      preset: "Förinställt",
      custom: "Anpassat",
      preview: "Förhandsgranska",
      share: "Dela",
      personalize: "Personalisera",
      view: "Visa",
      complete_your_profile: "Kompletta din profil",
      personal_details: "Personliga detaljer",
      customize_content_experience: "Anpassa innehållsupplevelse",
      share_on_whatsapp: "Dela på WhatsApp",
      language: "Språk",
      ai_description: "Vår AI-assistent skriver något speciellt för dig! Kopiera, klistra in, redigera och dela.",
      ai_regenerate: "Försök något annat",
      copy_text_create_post: "Kopiera text & skapa inlägg",
      copy_link_to_content_experience: "Kopiera länk till din innehållsupplevelse",
      email: "E-post",
      qr_code: "QR-kod",
      link: "Länk",
      my_experiences: "Mina upplevelser",
      my_profile: "Min profil",
      about_my_business: "Om mitt företag",
      i_am_a_travel_advisor: "Jag är en resebyrå/reseagent eller liknande tredjepartförsäljare av resor",
      host_agency: "Värdbyrå",
      agency_association_consortium: "Byråförening / Konsortium",
      booking_email: "Boknings-e-post",
      booking_phone_number: "Bokningstelefonnummer",
      iata_used_for_booking: "IATA använt för bokningar",
      clia_used_for_booking: "CLIA använt för bokningar",
      company_name: "Företagsnamn",
      business_languages: "Språk jag gör affärer på",
      update: "Uppdatera",
      saving: "Sparar",
      saved: "Sparad",
      logout: "Logga ut",
      cobrand_and_share: "Co-brand & dela",
      your_content_experiences: "Dina innehållsupplevelser",
      your_experiences: "Dina upplevelser",
      new_experiences: "Nya upplevelser",
      all_content_experiences: "Alla innehållsupplevelser",
      search: "Sök",
      experiences: "Upplevelser",
      cobranded_website: "Co-branded webbsida",
      about: "Om",
      your_partnerName_content_experiences: "Dina :partnerName innehållsupplevelser",
      content_experiences_description:
        "Innehållsupplevelser gör att du snabbt kan leverera inspirerande innehåll på ett högst personligt sätt. **Klicka på en upplevelse nedan för att komma igång.**",
      cobrand_the_partnerName_website: "Co-brand :partnerName webbsida",
      cobrand_website_url_description:
        "Här är din personliga co-branded :partnerName webbsida. Dela denna länk säkert med kunder – vi har transformerat sajten just för dig.",
      copy_link: "Kopiera länk",
      copied: "Kopierad",
      cobrand_website_any_url_description:
        "Det blir ännu bättre! Du kan co-branding alla sidor på :partnerName webbsidan. Skriv helt enkelt in vilken :partnerName webbsida som helst för att få din co-branded länk.",
      cobrand: "Co-brand",
      contact: "Kontakt",
      for_travel_brands: "För reseföretag",
      recommend: "Rekommendera",
      recommend_us_to_suppliers: "Rekommendera oss till dina favoritleverantörer",
      recommend_us_description:
        "Önskar du inte att alla dina leverantörer gör marknadsföring så här enkelt? Låt dem veta att de kan skapa innehållsupplevelser för att driva din marknadsföring. Klicka på knappen så skapar vi ett e-postmeddelande för dig!",
      cancel: "Avbryt",
      copy_and_paste_in_email: "Kopiera (och klistra in i ett e-postmeddelande)",
      pagination: ":itemsFrom - :itemsTo av :itemsTotal artiklar.",
      choose_a_content_experience: "Välj en innehållsupplevelse",
      sign_in: "Logga in",
      get_started: "Kom igång",
      sign_in_to_account: "Logga in på ditt konto",
      sign_in_description: "Fyll i din e-postadress och vi skickar en länk som loggar in dig automatiskt.",
      email_address: "E-postadress",
      no_account: "Har du inget konto?",
      sign_up_title: "Registrera dig för att börja dela co-branded upplevelser",
      sign_up_description: "På bara några sekunder kan du co-branda och dela våra innehållsupplevelser för att bygga upp ditt företag.",
      create_account: "Skapa konto",
      personalize_your_content_experience: "Personalisera denna innehållsupplevelse",
      invitation_description:
        "Vi har skapat denna innehållsupplevelse för att hjälpa ditt marknadsföring och öka konverteringen. Det är enkelt att anpassa – skriv bara in din e-postadress för att komma igång!",
      enter_email_legal: "Genom att ange din e-postadress godkänner du",
      and: "och",
      magic_link_email_sent_title: "Kolla din e-post!",
      magic_link_email_sent_description: "För att komma igång, klicka på magisk länk-knappen i e-postmeddelandet vi skickade till **:userEmail**",
      magic_link_legal: "Genom att klicka på den magiska länken godkänner du",
      preview_description: "Detta är en förhandsgranskning. För att se den verkliga gästupplevelsen, logga ut.",
      name: "Namn",
      logo_personal_photo: "Logo & Personlig Foto",
      location: "Plats",
      consortium: "Konsortium",
      booking_email_phone: "Bokningse-post & Telefon",
      home: "Hem",
      update_profile: "Uppdatera profil",
      profile_instructions: "Bekräfta eller fyll i de markerade fälten.",
      select: "Välj",
      country: "Land",
      state_region: "Stat / Region",
      homepage_title: "Turbo-ladda din marknadsföring och försäljning",
      homepage_subtitle: "med gratis co-branded innehållsupplevelser",
      share_qr_code_description: "Ladda ner koden eller ta en skärmdump för att dela ännu snabbare.",
      download_qr_code: "Ladda ner QR-kod",
      bilbert_email_start: "Jag skapar ett e-postmeddelande, personligt för dig! Ge mig några sekunder",
      bilbert_email_end: "Allt klart! Klicka på knappen nedan för att kopiera, och klistra sedan in den i ett e-postmeddelande och skicka det!",
      ai_robot: "AI Robot",
      sign_up: "Registrera dig",
      already_signed_up: "Redan registrerad?",
      cookies_description: "Vi använder cookies för att förbättra din upplevelse.",
      learn_more: "Lär dig mer",
      accept: "Acceptera",
      hooray: "Hurra!",
      experience_creation_description: "Ge oss ett ögonblick medan vi skapar din co-branded upplevelse...",
      share_link_everywhere: "Dela din länk överallt",
      sales_links_header: "Länkar att dela med partners",
      sales_links_description:
        "Deltar du i ett möte eller evenemang, håller ett webinar eller skickar ett e-postmeddelande? Detta är en komplett och uppdaterad resurs med allt du behöver för att bjuda in partners att co-branding och dela dina innehållsupplevelser.",
      step_one_header: "Kopiera inbjudningslänken eller ladda ner QR-koden",
      step_one_description:
        "Kopiera inbjudningslänken eller ladda ner QR-koden för alla :partnerName innehållsupplevelser, eller välj en specifik innehållsupplevelse.",
      step_one_tip: "Glöm inte att **testa** din QR-kod innan du delar den!",
      copy_invite_link: "Kopiera inbjudningslänk",
      copied_to_clipboard: "Kopierad till urklipp",
      or_choose_content_experience: "Eller välj en specifik innehållsupplevelse:",
      step_two_header: "Sätt in i din webinar presentation, e-post och sociala inlägg",
      step_two_description: "Sätt in det i din webinar presentation, e-post, sociala inlägg... var du än interagerar med handelspartners.",
      step_three_header: "Lägg till förklarande text",
      step_three_description: "Lägg till förklarande text för att hjälpa partners att förstå varför du delar länkarna och QR-koderna.",
      step_three_tip: "Glöm inte att klistra in din länk och/eller QR-kod!",
      sales_example_one: "Det är det enklaste sättet att sälja :partnerName. Klicka för att komma igång!",
      sales_example_two: "Klicka för att direkt co-branda och dela vår senaste innehållsupplevelse.",
      sales_example_three: "Klicka för att omedelbart börja sälja :partnerName",
      just_a_moment: "Ett ögonblick",
      thats_strange: "Det är konstigt...",
      invalid_magic_link: "Denna magiska länk är ogiltig. Vänligen klicka på magisk länk-knappen igen eller klistra in den associerade magiska URL:en",
      oops: "Oj!",
      magic_link_used: "Magiska länkar är för engångsbruk och denna har redan använts. Vänligen ange din e-postadress igen för att ta emot en ny magisk länk.",
      get_new_magic_link: "Få din nya magiska länk!",
      new_magic_link_sent: "Din magiska länk har gått ut, så vi har skickat en ny till dig.",
      magic_link_check_email: "Kolla din e-post och klicka för att logga in",
      cobrand_our_website: "Co-branda vår webbsida",
      required_field: "Detta fält är obligatoriskt.",
      sales_example_description: "Här är några exempel för att hjälpa dig komma igång:",
      confirm: "Bekräfta",
    },
    el: {
      first_name: "Όνομα",
      last_name: "Επώνυμο",
      phone_number: "Αριθμός τηλεφώνου",
      logo: "Λογότυπο",
      personal_photo: "Προσωπική φωτογραφία",
      personal_message: "Προσωπικό μήνυμα",
      call_to_action_button: "Κουμπί κλήσης προς δράση",
      button_text: "Κείμενο κουμπιού",
      none: "Καμία",
      preset: "Προεπιλογή",
      custom: "Προσαρμοσμένο",
      preview: "Προεπισκόπηση",
      share: "Μοιραστείτε",
      personalize: "Προσαρμόστε",
      view: "Προβολή",
      complete_your_profile: "Ολοκληρώστε το προφίλ σας",
      personal_details: "Προσωπικά στοιχεία",
      customize_content_experience: "Προσαρμόστε την εμπειρία περιεχομένου",
      share_on_whatsapp: "Μοιραστείτε στο WhatsApp",
      language: "Γλώσσα",
      ai_description: "Ο βοηθός μας AI γράφει κάτι ειδικά για εσάς! Απλώς αντιγράψτε, επικολλήστε, επεξεργαστείτε και μοιραστείτε.",
      ai_regenerate: "Δοκιμάστε κάτι διαφορετικό",
      copy_text_create_post: "Αντιγράψτε κείμενο & δημιουργήστε ανάρτηση",
      copy_link_to_content_experience: "Αντιγράψτε το σύνδεσμο στην εμπειρία περιεχομένου σας",
      email: "Ηλεκτρονικό ταχυδρομείο",
      qr_code: "Κωδικός QR",
      link: "Σύνδεσμος",
      my_experiences: "Οι εμπειρίες μου",
      my_profile: "Το προφίλ μου",
      about_my_business: "Σχετικά με την επιχείρησή μου",
      i_am_a_travel_advisor: "Είμαι ταξιδιωτικός σύμβουλος/γραφείο ή παρόμοιος τρίτος πωλητής ταξιδιών",
      host_agency: "Πρακτορείο φιλοξενίας",
      agency_association_consortium: "Ένωση γραφείων / Κοινοπραξία",
      booking_email: "Ηλεκτρονικό ταχυδρομείο κρατήσεων",
      booking_phone_number: "Αριθμός τηλεφώνου κρατήσεων",
      iata_used_for_booking: "IATA που χρησιμοποιείται για κρατήσεις",
      clia_used_for_booking: "CLIA που χρησιμοποιείται για κρατήσεις",
      company_name: "Όνομα εταιρείας",
      business_languages: "Γλώσσες στις οποίες διεξάγω τις επιχειρήσεις",
      update: "Ενημέρωση",
      saving: "Αποθήκευση",
      saved: "Αποθηκευμένο",
      logout: "Αποσύνδεση",
      cobrand_and_share: "Co-brand & μοιραστείτε",
      your_content_experiences: "Οι εμπειρίες περιεχομένου σας",
      your_experiences: "Οι εμπειρίες σας",
      new_experiences: "Νέες εμπειρίες",
      all_content_experiences: "Όλες οι εμπειρίες περιεχομένου",
      search: "Αναζήτηση",
      experiences: "Εμπειρίες",
      cobranded_website: "Co-branded ιστότοπος",
      about: "Σχετικά",
      your_partnerName_content_experiences: "Οι εμπειρίες περιεχομένου :partnerName",
      content_experiences_description:
        "Οι εμπειρίες περιεχομένου σας επιτρέπουν να παραδώσετε γρήγορα εμπνευσμένο περιεχόμενο με έναν εξαιρετικά εξατομικευμένο τρόπο. **Κάντε κλικ σε μια εμπειρία παρακάτω για να ξεκινήσετε.**",
      cobrand_the_partnerName_website: "Co-brand τον ιστότοπο :partnerName",
      cobrand_website_url_description:
        "Αυτός είναι ο προσωπικός σας, co-branded ιστότοπος :partnerName. Μοιραστείτε αυτόν τον σύνδεσμο με ασφάλεια με τους πελάτες σας - έχουμε μετατρέψει την τοποθεσία μόνο για εσάς.",
      copy_link: "Αντιγράψτε τον σύνδεσμο",
      copied: "Αντιγράφηκε",
      cobrand_website_any_url_description:
        "Γίνεται ακόμα καλύτερο! Μπορείτε να co-brand οποιαδήποτε σελίδα στον ιστότοπο :partnerName. Απλώς εισαγάγετε οποιαδήποτε σελίδα του :partnerName για να αποκτήσετε το co-branded σύνδεσμο σας.",
      cobrand: "Co-brand",
      contact: "Επικοινωνία",
      for_travel_brands: "Για ταξιδιωτικά εμπορικά σήματα",
      recommend: "Συνιστώ",
      recommend_us_to_suppliers: "Συστήστε μας στους αγαπημένους σας προμηθευτές",
      recommend_us_description:
        "Δεν θα ήταν ωραίο αν όλοι οι προμηθευτές σας έκαναν το μάρκετινγκ τόσο εύκολο; Ενημερώστε τους ότι μπορούν να δημιουργήσουν εμπειρίες περιεχομένου για να ενισχύσουν το μάρκετινγκ σας. Κάντε κλικ στο κουμπί και ας δημιουργήσουμε ένα email για εσάς!",
      cancel: "Ακύρωση",
      copy_and_paste_in_email: "Αντιγράψτε (και επικολλήστε σε email)",
      pagination: ":itemsFrom - :itemsTo από :itemsTotal αντικείμενα.",
      choose_a_content_experience: "Επιλέξτε μια εμπειρία περιεχομένου",
      sign_in: "Σύνδεση",
      get_started: "Αρχίστε",
      sign_in_to_account: "Συνδεθείτε στο λογαριασμό σας",
      sign_in_description: "Εισαγάγετε το email σας και θα σας στείλουμε έναν σύνδεσμο που θα σας συνδέσει αυτόματα.",
      email_address: "Διεύθυνση email",
      no_account: "Δεν έχετε λογαριασμό;",
      sign_up_title: "Εγγραφείτε για να ξεκινήσετε να μοιράζεστε εμπειρίες co-brand",
      sign_up_description: "Σε λίγα δευτερόλεπτα, μπορείτε να co-brand και να μοιραστείτε τις εμπειρίες περιεχομένου μας για να χτίσετε την επιχείρησή σας.",
      create_account: "Δημιουργία λογαριασμού",
      personalize_your_content_experience: "Προσαρμόστε αυτή την εμπειρία περιεχομένου",
      invitation_description:
        "Δημιουργήσαμε αυτή την εμπειρία περιεχομένου για να ενισχύσουμε το μάρκετινγκ σας και να ενισχύσουμε τις μετατροπές. Είναι εύκολο να προσαρμοστεί – απλώς εισαγάγετε το email σας για να ξεκινήσετε!",
      enter_email_legal: "Με την εισαγωγή της διεύθυνσης email, συμφωνείτε με τους",
      and: "και",
      magic_link_email_sent_title: "Ελέγξτε το email σας!",
      magic_link_email_sent_description: "Για να ξεκινήσετε, κάντε κλικ στο κουμπί της μαγικής σύνδεσης στο email που στείλαμε στο **:userEmail**",
      magic_link_legal: "Κάνοντας κλικ στην μαγική σύνδεση, συμφωνείτε με τους",
      preview_description: "Αυτή είναι μια προεπισκόπηση. Για να δείτε την πραγματική εμπειρία του επισκέπτη, αποσυνδεθείτε.",
      name: "Όνομα",
      logo_personal_photo: "Λογότυπο & Προσωπική φωτογραφία",
      location: "Τοποθεσία",
      consortium: "Συνεταιρισμός",
      booking_email_phone: "Email & Τηλέφωνο Κρατήσεων",
      home: "Αρχική",
      update_profile: "Ενημέρωση προφίλ",
      profile_instructions: "Επιβεβαιώστε ή συμπληρώστε τα επισημασμένα πεδία.",
      select: "Επιλέξτε",
      country: "Χώρα",
      state_region: "Πολιτεία / Περιοχή",
      homepage_title: "Ενισχύστε τις προσπάθειες μάρκετινγκ και πωλήσεων",
      homepage_subtitle: "με δωρεάν εμπειρίες περιεχομένου co-branded",
      share_qr_code_description: "Κατεβάστε τον κώδικα ή κάντε στιγμιότυπο οθόνης για να το μοιραστείτε ακόμα πιο γρήγορα.",
      download_qr_code: "Κατεβάστε τον κώδικα QR",
      bilbert_email_start: "Δημιουργώ ένα email, προσωπικά για εσάς! Δώστε μου λίγα δευτερόλεπτα",
      bilbert_email_end: "Έγινε! Απλώς κάντε κλικ στο κουμπί παρακάτω για να το αντιγράψετε και, στη συνέχεια, επικολλήστε το σε ένα email και στείλτε το!",
      ai_robot: "AI Ρομπότ",
      sign_up: "Εγγραφή",
      already_signed_up: "Ήδη εγγεγραμμένοι;",
      cookies_description: "Χρησιμοποιούμε cookies για να βελτιώσουμε την εμπειρία σας.",
      learn_more: "Μάθετε περισσότερα",
      accept: "Αποδοχή",
      hooray: "Ώρα για γιορτές!",
      experience_creation_description: "Δώστε μας μια στιγμή ενώ δημιουργούμε την εμπειρία σας με το co-brand...",
      share_link_everywhere: "Μοιραστείτε το σύνδεσμο παντού",
      sales_links_header: "Συνδέσμοι για να μοιραστείτε με τους συνεργάτες",
      sales_links_description:
        "Παρακολουθείτε μια συνάντηση ή εκδήλωση, κάνετε ένα σεμινάριο, στέλνετε ένα email; Αυτή είναι μια πλήρης, ενημερωμένη πηγή με όλα όσα χρειάζεστε για να προσκαλέσετε συνεργάτες να συν-συμβάλλουν και να μοιραστούν τις εμπειρίες σας με περιεχόμενο.",
      step_one_header: "Αντιγράψτε το σύνδεσμο προσκλήσεως ή κατεβάστε τον QR κωδικό",
      step_one_description:
        "Αντιγράψτε το σύνδεσμο πρόσκλησης ή κατεβάστε τον QR κωδικό για όλες τις εμπειρίες περιεχομένου :partnerName, ή επιλέξτε μια συγκεκριμένη εμπειρία περιεχομένου.",
      step_one_tip: "Μην ξεχάσετε να **δοκιμάσετε** τον QR κωδικό σας πριν τον μοιραστείτε!",
      copy_invite_link: "Αντιγράψτε το σύνδεσμο πρόσκλησης",
      copied_to_clipboard: "Αντιγράφηκε στο πρόχειρο",
      or_choose_content_experience: "Ή επιλέξτε μια συγκεκριμένη εμπειρία περιεχομένου:",
      step_two_header: "Τοποθετήστε το στην παρουσίαση του webinar, στο email και στις δημοσιεύσεις κοινωνικών μέσων",
      step_two_description:
        "Τοποθετήστε το στην παρουσίαση του webinar, στο email, στις δημοσιεύσεις στα κοινωνικά δίκτυα... όπου και αν αλληλεπιδράτε με συνεργάτες.",
      step_three_header: "Προσθέστε επεξηγηματικό κείμενο",
      step_three_description:
        "Προσθέστε επεξηγηματικό κείμενο για να βοηθήσετε τους συνεργάτες να καταλάβουν γιατί μοιράζεστε τους συνδέσμους και τους QR κωδικούς.",
      step_three_tip: "Μην ξεχάσετε να επικολλήσετε το σύνδεσμο και/ή τον QR κωδικό!",
      sales_example_one: "Είναι ο ευκολότερος τρόπος για να πουλήσετε :partnerName. Κάντε κλικ για να ξεκινήσετε!",
      sales_example_two: "Κάντε κλικ για να συν-συμβάλλετε και να μοιραστείτε αμέσως την τελευταία εμπειρία περιεχομένου μας.",
      sales_example_three: "Κάντε κλικ για να ξεκινήσετε την πώληση του :partnerName αμέσως",
      just_a_moment: "Μια στιγμή",
      thats_strange: "Αυτό είναι περίεργο...",
      invalid_magic_link:
        "Αυτός ο μαγικός σύνδεσμος είναι άκυρος. Κάντε κλικ ξανά στο κουμπί του μαγικού συνδέσμου ή επικολλήστε την αντίστοιχη URL του μαγικού συνδέσμου",
      oops: "Ωχ!",
      magic_link_used:
        "Οι μαγικοί σύνδεσμοι είναι για χρήση μόνο μια φορά και αυτός έχει ήδη χρησιμοποιηθεί. Παρακαλώ εισάγετε ξανά τη διεύθυνση email σας για να λάβετε έναν νέο μαγικό σύνδεσμο.",
      get_new_magic_link: "Λάβετε τον νέο μαγικό σας σύνδεσμο!",
      new_magic_link_sent: "Ο μαγικός σας σύνδεσμος έληξε, οπότε σας έχουμε στείλει έναν νέο.",
      magic_link_check_email: "Ελέγξτε το email σας και κάντε κλικ για να συνδεθείτε",
      cobrand_our_website: "Συνεργαστείτε με την ιστοσελίδα μας",
      required_field: "Αυτό το πεδίο είναι υποχρεωτικό.",
      sales_example_description: "Ακολουθούν μερικά παραδείγματα για να σας βοηθήσουν να ξεκινήσετε:",
      confirm: "Επιβεβαίωση",
    },
    da: {
      first_name: "Fornavn",
      last_name: "Efternavn",
      phone_number: "Telefonnummer",
      logo: "Logo",
      personal_photo: "Personligt foto",
      personal_message: "Personlig besked",
      call_to_action_button: "Call-to-action-knap",
      button_text: "Knaptekst",
      none: "Ingen",
      preset: "Forudindstillet",
      custom: "Brugerdefineret",
      preview: "Forhåndsvisning",
      share: "Del",
      personalize: "Personliggør",
      view: "Vis",
      complete_your_profile: "Udfyld din profil",
      personal_details: "Personlige detaljer",
      customize_content_experience: "Tilpas indholdoplevelse",
      share_on_whatsapp: "Del på WhatsApp",
      language: "Sprog",
      ai_description: "Vores AI-assistent skriver noget specielt til dig! Kopier, indsæt, rediger og del.",
      ai_regenerate: "Prøv noget andet",
      copy_text_create_post: "Kopier tekst og opret opslag",
      copy_link_to_content_experience: "Kopier link til din indholdoplevelse",
      email: "E-mail",
      qr_code: "QR-kode",
      link: "Link",
      my_experiences: "Mine oplevelser",
      my_profile: "Min profil",
      about_my_business: "Om mit firma",
      i_am_a_travel_advisor: "Jeg er en rejsearrangør/rejsebureau eller en lignende tredjeparts rejse sælger",
      host_agency: "Værtsbureau",
      agency_association_consortium: "Bureauforening / Konsortium",
      booking_email: "Booking E-mail",
      booking_phone_number: "Booking telefonnummer",
      iata_used_for_booking: "IATA brugt til booking",
      clia_used_for_booking: "CLIA brugt til booking",
      company_name: "Firmanavn",
      business_languages: "Sprog jeg gør forretning på",
      update: "Opdater",
      saving: "Gemmer",
      saved: "Gemte",
      logout: "Log ud",
      cobrand_and_share: "Co-brand & del",
      your_content_experiences: "Dine indholdoplevelser",
      your_experiences: "Dine oplevelser",
      new_experiences: "Nye oplevelser",
      all_content_experiences: "Alle indholdoplevelser",
      search: "Søg",
      experiences: "Oplevelser",
      cobranded_website: "Co-branded hjemmeside",
      about: "Om",
      your_partnerName_content_experiences: "Dine :partnerName indholdoplevelser",
      content_experiences_description:
        "Indholdoplevelser giver dig mulighed for hurtigt at levere inspirerende indhold på en meget personlig måde. **Klik på en oplevelse nedenfor for at komme i gang.**",
      cobrand_the_partnerName_website: "Co-brand :partnerName hjemmeside",
      cobrand_website_url_description:
        "Her er din personlige co-branded :partnerName hjemmeside. Del denne link sikkert med kunder – vi har omdannet siden kun for dig.",
      copy_link: "Kopier link",
      copied: "Kopieret",
      cobrand_website_any_url_description:
        "Det bliver endnu bedre! Du kan co-brande enhver side på :partnerName hjemmesiden. Indtast blot en hvilken som helst :partnerName webside for at få dit co-branded link.",
      cobrand: "Co-brand",
      contact: "Kontakt",
      for_travel_brands: "For rejsemærker",
      recommend: "Anbefal",
      recommend_us_to_suppliers: "Anbefal os til dine yndlingsleverandører",
      recommend_us_description:
        "Vil du ikke have, at alle dine leverandører gør markedsføring så nemt? Lad dine leverandører vide, at de kan skabe indholdoplevelser for at styrke dit markedsføring. Klik på knappen, og lad os oprette en e-mail for dig!",
      cancel: "Annuller",
      copy_and_paste_in_email: "Kopier (og indsæt i en e-mail)",
      pagination: ":itemsFrom - :itemsTo af :itemsTotal emner.",
      choose_a_content_experience: "Vælg en indholdoplevelse",
      sign_in: "Log ind",
      get_started: "Kom i gang",
      sign_in_to_account: "Log ind på din konto",
      sign_in_description: "Indtast din e-mail, og vi sender dig et link, der logger dig ind automatisk.",
      email_address: "E-mailadresse",
      no_account: "Har du ikke en konto?",
      sign_up_title: "Tilmeld dig for at begynde at dele co-branded oplevelser",
      sign_up_description: "På få sekunder kan du co-brande og dele vores indholdoplevelser for at opbygge din virksomhed.",
      create_account: "Opret konto",
      personalize_your_content_experience: "Personliggør denne indholdoplevelse",
      invitation_description:
        "Vi har skabt denne indholdoplevelse for at styrke dit markedsføring og øge konverteringen. Det er nemt at tilpasse – indtast blot din e-mail for at komme i gang!",
      enter_email_legal: "Ved at indtaste din e-mailadresse accepterer du",
      and: "og",
      magic_link_email_sent_title: "Tjek din e-mail!",
      magic_link_email_sent_description: "For at komme i gang, klik på magic link-knappen i den e-mail, vi sendte til **:userEmail**",
      magic_link_legal: "Ved at klikke på magic link accepterer du",
      preview_description: "Dette er en forhåndsvisning. For at se den faktiske gæsteoplevelse, log ud.",
      name: "Navn",
      logo_personal_photo: "Logo og personlig foto",
      location: "Placering",
      consortium: "Konsortium",
      booking_email_phone: "Booking E-mail & Telefon",
      home: "Hjem",
      update_profile: "Opdater profil",
      profile_instructions: "Bekræft eller udfyld de fremhævede felter.",
      select: "Vælg",
      country: "Land",
      state_region: "Stat / Region",
      homepage_title: "Styrk dine marketing- og salgsindsatser",
      homepage_subtitle: "med gratis co-branded indholdoplevelser",
      share_qr_code_description: "Download koden eller tag et skærmbillede for endnu hurtigere deling.",
      download_qr_code: "Download QR-kode",
      bilbert_email_start: "Jeg laver en e-mail, personligt til dig! Giv mig et øjeblik",
      bilbert_email_end: "Færdig! Klik blot på knappen nedenfor for at kopiere, og indsæt den derefter i en e-mail og send!",
      ai_robot: "AI Robot",
      sign_up: "Tilmeld dig",
      already_signed_up: "Allerede tilmeldt?",
      cookies_description: "Vi bruger cookies for at forbedre din oplevelse.",
      learn_more: "Lær mere",
      accept: "Accepter",
      hooray: "Hurra!",
      experience_creation_description: "Giv os et øjeblik, mens vi opretter din co-branded oplevelse...",
      share_link_everywhere: "Del din link overalt",
      sales_links_header: "Links til at dele med partnere",
      sales_links_description:
        "Deltager du i et møde eller event, afholder du et webinar eller sender du en e-mail? Dette er en komplet, opdateret ressource med alt hvad du behøver for at invitere partnere til at co-brande og dele dine indholdoplevelser.",
      step_one_header: "Kopier invitation link eller download QR-kode",
      step_one_description:
        "Kopier invitation link eller download QR-koden for enten alle :partnerName indholdoplevelser, eller vælg en specifik indholdoplevelse.",
      step_one_tip: "Glem ikke at **teste** din QR-kode før du deler!",
      copy_invite_link: "Kopier invitation link",
      copied_to_clipboard: "Kopieret til udklipsholder",
      or_choose_content_experience: "Eller vælg en specifik indholdoplevelse:",
      step_two_header: "Sæt det ind i dit webinar præsentation, e-mail og sociale medier indlæg",
      step_two_description: "Sæt det ind i dit webinar præsentation, e-mail, sociale medie indlæg... hvor du end er i kontakt med handelspartnere.",
      step_three_header: "Tilføj forklarende tekst",
      step_three_description: "Tilføj forklarende tekst for at hjælpe partnere med at forstå hvorfor du deler links og QR koder.",
      step_three_tip: "Glem ikke at indsætte din link og/eller QR-kode!",
      sales_example_one: "Det er den nemmeste måde at sælge :partnerName. Klik for at starte!",
      sales_example_two: "Klik for at co-brande og dele vores nyeste indholdoplevelse.",
      sales_example_three: "Klik for at starte med at sælge :partnerName",
      just_a_moment: "Et øjeblik",
      thats_strange: "Det er mærkeligt...",
      invalid_magic_link: "Denne magiske link er ugyldig. Klik på magiske link knappen igen eller indsæt den tilknyttede magiske URL",
      oops: "Ups!",
      magic_link_used: "Magiske links er til engangsbrug, og dette er allerede blevet brugt. Indtast din e-mailadresse igen for at modtage et nyt magisk link.",
      get_new_magic_link: "Få dit nye magiske link!",
      new_magic_link_sent: "Dit magiske link er udløbet, så vi har sendt dig et nyt.",
      magic_link_check_email: "Tjek din e-mail og klik for at logge ind",
      cobrand_our_website: "Co-brand vores hjemmeside",
      required_field: "Dette felt er påkrævet.",
      sales_example_description: "Her er nogle eksempler for at hjælpe dig i gang:",
      confirm: "Bekræft",
    },
    ja: {
      first_name: "名前",
      last_name: "苗字",
      phone_number: "電話番号",
      logo: "ロゴ",
      personal_photo: "個人写真",
      personal_message: "個人メッセージ",
      call_to_action_button: "行動を促すボタン",
      button_text: "ボタンのテキスト",
      none: "なし",
      preset: "プリセット",
      custom: "カスタム",
      preview: "プレビュー",
      share: "共有",
      personalize: "パーソナライズ",
      view: "表示",
      complete_your_profile: "プロフィールを完成させてください",
      personal_details: "個人情報",
      customize_content_experience: "コンテンツ体験をカスタマイズ",
      share_on_whatsapp: "WhatsAppで共有",
      language: "言語",
      ai_description: "AIアシスタントがあなたのために何かを書いています！ コピーして、貼り付けて、編集して、共有してください。",
      ai_regenerate: "別のものを試す",
      copy_text_create_post: "テキストをコピーして投稿を作成",
      copy_link_to_content_experience: "コンテンツ体験へのリンクをコピー",
      email: "メール",
      qr_code: "QRコード",
      link: "リンク",
      my_experiences: "私の体験",
      my_profile: "私のプロフィール",
      about_my_business: "私のビジネスについて",
      i_am_a_travel_advisor: "私は旅行代理店/旅行エージェントまたは同様の第三者旅行販売者です",
      host_agency: "ホストエージェンシー",
      agency_association_consortium: "エージェンシー協会 / コンソーシアム",
      booking_email: "予約メール",
      booking_phone_number: "予約電話番号",
      iata_used_for_booking: "予約に使用するIATA",
      clia_used_for_booking: "予約に使用するCLIA",
      company_name: "会社名",
      business_languages: "取引に使用する言語",
      update: "更新",
      saving: "保存中",
      saved: "保存済み",
      logout: "ログアウト",
      cobrand_and_share: "共同ブランド化して共有",
      your_content_experiences: "あなたのコンテンツ体験",
      your_experiences: "あなたの体験",
      new_experiences: "新しい体験",
      all_content_experiences: "すべてのコンテンツ体験",
      search: "検索",
      experiences: "体験",
      cobranded_website: "共同ブランドウェブサイト",
      about: "概要",
      your_partnerName_content_experiences: ":partnerNameのコンテンツ体験",
      content_experiences_description:
        "コンテンツ体験を通じて、高度にパーソナライズされた方法でインスピレーションを与えるコンテンツを迅速に提供できます。**下の体験をクリックして、開始してください。**",
      cobrand_the_partnerName_website: ":partnerNameのウェブサイトを共同ブランド化",
      cobrand_website_url_description:
        "こちらがあなたの個人的な共同ブランド化された:partnerNameウェブサイトです。このリンクを安全にクライアントと共有してください—私たちはあなたのためにサイトを変換しました。",
      copy_link: "リンクをコピー",
      copied: "コピーしました",
      cobrand_website_any_url_description:
        "さらに良くなります！:partnerNameウェブサイトの任意のページを共同ブランド化できます。任意の:partnerNameウェブページを入力して、共同ブランド化されたリンクを取得してください。",
      cobrand: "共同ブランド化",
      contact: "お問い合わせ",
      for_travel_brands: "旅行ブランド向け",
      recommend: "推薦",
      recommend_us_to_suppliers: "お気に入りのサプライヤーに推薦",
      recommend_us_description:
        "すべてのサプライヤーがこんなに簡単にマーケティングをしてくれたらいいと思いませんか？サプライヤーに、コンテンツ体験を作成してマーケティングを支援できることを知らせてください。ボタンをクリックして、私たちがメールを作成します！",
      cancel: "キャンセル",
      copy_and_paste_in_email: "コピー（そしてメールに貼り付け）",
      pagination: ":itemsFrom - :itemsTo of :itemsTotal items.",
      choose_a_content_experience: "コンテンツ体験を選択",
      sign_in: "サインイン",
      get_started: "開始",
      sign_in_to_account: "アカウントにサインイン",
      sign_in_description: "メールアドレスを入力すると、自動的にサインインできるリンクをお送りします。",
      email_address: "メールアドレス",
      no_account: "アカウントをお持ちでないですか？",
      sign_up_title: "サインアップして共同ブランド化体験を共有する",
      sign_up_description: "数秒で、コンテンツ体験を共同ブランド化して共有し、ビジネスを構築できます。",
      create_account: "アカウントを作成",
      personalize_your_content_experience: "このコンテンツ体験をパーソナライズ",
      invitation_description:
        "このコンテンツ体験は、あなたのマーケティングを支援し、コンバージョンを促進するために作成しました。カスタマイズも簡単です—メールアドレスを入力して始めてください！",
      enter_email_legal: "メールアドレスを入力することにより、あなたは次のことに同意したことになります",
      and: "と",
      magic_link_email_sent_title: "メールをご確認ください！",
      magic_link_email_sent_description: "始めるには、私たちが**:userEmail**に送ったメールの中のマジックリンクボタンをクリックしてください",
      magic_link_legal: "マジックリンクをクリックすることにより、あなたは次のことに同意したことになります",
      preview_description: "これはプレビューです。実際のゲスト体験を見るには、ログアウトしてください。",
      name: "名前",
      logo_personal_photo: "ロゴと個人写真",
      location: "場所",
      consortium: "コンソーシアム",
      booking_email_phone: "予約メールと電話",
      home: "ホーム",
      update_profile: "プロフィールを更新",
      profile_instructions: "ハイライトされたフィールドを確認または入力してください。",
      select: "選択",
      country: "国",
      state_region: "州/地域",
      homepage_title: "マーケティングと販売の取り組みを強化",
      homepage_subtitle: "無料の共同ブランド化コンテンツ体験で",
      share_qr_code_description: "コードをダウンロードするか、スクリーンショットを撮ってさらに早く共有。",
      download_qr_code: "QRコードをダウンロード",
      bilbert_email_start: "私はあなたのために個人的にメールを作成しています！ 少しだけお待ちください",
      bilbert_email_end: "完了しました！ 以下のボタンをクリックしてコピーし、メールに貼り付けて送信してください！",
      ai_robot: "AIロボット",
      sign_up: "サインアップ",
      already_signed_up: "すでにサインアップしていますか？",
      cookies_description: "私たちは、あなたの体験を改善するためにクッキーを使用しています。",
      learn_more: "もっと学ぶ",
      accept: "同意",
      hooray: "万歳！",
      experience_creation_description: "共同ブランド体験を作成していますので、少々お待ちください...",
      share_link_everywhere: "リンクをあらゆる場所でシェア",
      sales_links_header: "パートナーと共有するリンク",
      sales_links_description:
        "会議やイベントに参加する、ウェビナーを行う、メールを送信する？ これは、パートナーを招待してコンテンツ体験を共同ブランド化して共有するために必要なすべての最新情報が揃った完全なリソースです。",
      step_one_header: "招待リンクをコピーするか、QRコードをダウンロードする",
      step_one_description:
        "すべての:partnerNameコンテンツ体験の招待リンクをコピーするか、QRコードをダウンロードします。もしくは、特定のコンテンツ体験を選択してください。",
      step_one_tip: "QRコードを共有する前に**テスト**することを忘れないでください！",
      copy_invite_link: "招待リンクをコピー",
      copied_to_clipboard: "クリップボードにコピーしました",
      or_choose_content_experience: "または特定のコンテンツ体験を選択してください：",
      step_two_header: "ウェビナーのプレゼンテーション、メール、ソーシャル投稿に追加",
      step_two_description: "ウェビナーのプレゼンテーション、メール、ソーシャル投稿に追加...パートナーとやりとりしている場所ならどこでも使えます。",
      step_three_header: "説明文を追加",
      step_three_description: "リンクやQRコードを共有する理由をパートナーに理解してもらうための説明文を追加してください。",
      step_three_tip: "リンクと/またはQRコードを貼り付けることを忘れないでください！",
      sales_example_one: ":partnerNameを販売する最も簡単な方法です。開始するにはクリック！",
      sales_example_two: "クリックして、私たちの最新のコンテンツ体験をすぐに共同ブランド化して共有。",
      sales_example_three: "クリックしてすぐに:partnerNameの販売を開始",
      just_a_moment: "少々お待ちください",
      thats_strange: "それは奇妙です...",
      invalid_magic_link: "この魔法のリンクは無効です。魔法のリンクボタンを再度クリックするか、関連する魔法のURLを貼り付けてください。",
      oops: "おっと！",
      magic_link_used:
        "魔法のリンクは一度限りの使用で、このリンクはすでに使用されています。新しい魔法のリンクを受け取るために、再度メールアドレスを入力してください。",
      get_new_magic_link: "新しい魔法のリンクを取得！",
      new_magic_link_sent: "あなたの魔法のリンクは期限切れですので、新しいリンクを送信しました。",
      magic_link_check_email: "メールを確認し、ログインするためにクリックしてください。",
      cobrand_our_website: "当社のウェブサイトを共同ブランド化",
      required_field: "このフィールドは必須です。",
      sales_example_description: "開始するためのいくつかの例を紹介します：",
      confirm: "確認",
    },
    no: {
      first_name: "Fornavn",
      last_name: "Etternavn",
      phone_number: "Telefonnummer",
      logo: "Logo",
      personal_photo: "Personlig foto",
      personal_message: "Personlig melding",
      call_to_action_button: "Handlingsknapp",
      button_text: "Knapptekst",
      none: "Ingen",
      preset: "Forhåndsinnstilt",
      custom: "Egendefinert",
      preview: "Forhåndsvisning",
      share: "Del",
      personalize: "Personliggjør",
      view: "Vis",
      complete_your_profile: "Fullfør profilen din",
      personal_details: "Personlige detaljer",
      customize_content_experience: "Tilpass innholdserfaring",
      share_on_whatsapp: "Del på WhatsApp",
      language: "Språk",
      ai_description: "Vår AI-assistent skriver noe spesielt for deg! Kopier, lim inn, rediger og del.",
      ai_regenerate: "Prøv noe annet",
      copy_text_create_post: "Kopier tekst og lag innlegg",
      copy_link_to_content_experience: "Kopier link til innholdserfaringen din",
      email: "E-post",
      qr_code: "QR-kode",
      link: "Link",
      my_experiences: "Mine erfaringer",
      my_profile: "Min profil",
      about_my_business: "Om min virksomhet",
      i_am_a_travel_advisor: "Jeg er en reisebyrå/reiseagent eller en lignende tredjeparts reiseselger",
      host_agency: "Vertsbureau",
      agency_association_consortium: "Byråforening / Konsortium",
      booking_email: "Bestillings-e-post",
      booking_phone_number: "Bestillings-telefonnummer",
      iata_used_for_booking: "IATA brukt til bestilling",
      clia_used_for_booking: "CLIA brukt til bestilling",
      company_name: "Firmanavn",
      business_languages: "Språk jeg gjør forretninger på",
      update: "Oppdater",
      saving: "Lagrer",
      saved: "Lagret",
      logout: "Logg ut",
      cobrand_and_share: "Co-brand & del",
      your_content_experiences: "Dine innholdserfaringer",
      your_experiences: "Dine erfaringer",
      new_experiences: "Nye erfaringer",
      all_content_experiences: "Alle innholdserfaringer",
      search: "Søk",
      experiences: "Erfaringer",
      cobranded_website: "Co-branded nettsted",
      about: "Om",
      your_partnerName_content_experiences: "Dine :partnerName innholdserfaringer",
      content_experiences_description:
        "Innholdserfaringer lar deg raskt levere inspirerende innhold på en svært personlig måte. **Klikk på en opplevelse nedenfor for å komme i gang.**",
      cobrand_the_partnerName_website: "Co-brand :partnerName nettsted",
      cobrand_website_url_description:
        "Her er ditt personlige co-branded :partnerName nettsted. Del denne linken trygt med kundene dine - vi har transformert nettstedet bare for deg.",
      copy_link: "Kopier link",
      copied: "Kopiert",
      cobrand_website_any_url_description:
        "Det blir enda bedre! Du kan co-brand alle sider på :partnerName nettsted. Skriv bare inn hvilken som helst :partnerName webside for å få co-branded linken din.",
      cobrand: "Co-brand",
      contact: "Kontakt",
      for_travel_brands: "For reisemerker",
      recommend: "Anbefale",
      recommend_us_to_suppliers: "Anbefal oss til dine favorittleverandører",
      recommend_us_description:
        "Ønsker du ikke at alle leverandørene dine skulle gjøre markedsføring så lett? La leverandørene dine vite at de kan lage innholdserfaringer for å styrke markedsføringen din. Klikk på knappen og la oss lage en e-post for deg!",
      cancel: "Avbryt",
      copy_and_paste_in_email: "Kopier (og lim inn i en e-post)",
      pagination: ":itemsFrom - :itemsTo av :itemsTotal elementer.",
      choose_a_content_experience: "Velg en innholdserfaring",
      sign_in: "Logg inn",
      get_started: "Kom i gang",
      sign_in_to_account: "Logg inn på kontoen din",
      sign_in_description: "Skriv inn e-posten din, så sender vi en link som logger deg inn automatisk.",
      email_address: "E-postadresse",
      no_account: "Har du ikke en konto?",
      sign_up_title: "Registrer deg for å begynne å dele co-branded erfaringer",
      sign_up_description: "På bare noen sekunder kan du co-brande og dele våre innholdserfaringer for å bygge din virksomhet.",
      create_account: "Opprett konto",
      personalize_your_content_experience: "Personliggjør denne innholdserfaringen",
      invitation_description:
        "Vi har laget denne innholdserfaringen for å styrke markedsføringen din og øke konverteringen. Det er enkelt å tilpasse—bare skriv inn e-posten din for å komme i gang!",
      enter_email_legal: "Ved å skrive inn e-postadressen din, godtar du",
      and: "og",
      magic_link_email_sent_title: "Sjekk e-posten din!",
      magic_link_email_sent_description: "For å komme i gang, klikker du på magisk link-knappen i e-posten vi sendte til **:userEmail**",
      magic_link_legal: "Ved å klikke på den magiske linken, godtar du",
      preview_description: "Dette er en forhåndsvisning. For å se den faktiske gjesteopplevelsen, logg ut.",
      name: "Navn",
      logo_personal_photo: "Logo & personlig foto",
      location: "Plassering",
      consortium: "Konsortium",
      booking_email_phone: "Bestillings-e-post og telefon",
      home: "Hjem",
      update_profile: "Oppdater profil",
      profile_instructions: "Bekreft eller fyll ut de uthevede feltene.",
      select: "Velg",
      country: "Land",
      state_region: "Stat / Region",
      homepage_title: "Boost markedsføringen og salgsarbeidet ditt",
      homepage_subtitle: "med gratis co-branded innholdserfaringer",
      share_qr_code_description: "Last ned koden eller ta et skjermbilde for å dele raskere.",
      download_qr_code: "Last ned QR-kode",
      bilbert_email_start: "Jeg lager en e-post, personlig for deg! Gi meg et øyeblikk",
      bilbert_email_end: "Alt ferdig! Klikk på knappen nedenfor for å kopiere, og lim den deretter inn i en e-post og send den!",
      ai_robot: "AI Robot",
      sign_up: "Registrer deg",
      already_signed_up: "Allerede registrert?",
      cookies_description: "Vi bruker informasjonskapsler for å forbedre din opplevelse.",
      learn_more: "Lær mer",
      accept: "Godta",
      hooray: "Hurra!",
      experience_creation_description: "Gi oss et øyeblikk mens vi lager din co-branded opplevelse...",
      share_link_everywhere: "Del linken din overalt",
      sales_links_header: "Lenker for å dele med partnere",
      sales_links_description:
        "Deltar du på et møte eller arrangement, holder du et webinar eller sender du en e-post? Dette er en komplett, oppdatert ressurs med alt du trenger for å invitere partnere til å co-brande og dele innholdserfaringene dine.",
      step_one_header: "Kopier invitasjonslinken eller last ned QR-koden",
      step_one_description:
        "Kopier invitasjonslinken eller last ned QR-koden for enten alle :partnerName innholdserfaringene, eller velg en spesifikk innholdserfaring.",
      step_one_tip: "Ikke glem å **teste** QR-koden før du deler den!",
      copy_invite_link: "Kopier invitasjonslink",
      copied_to_clipboard: "Kopiert til utklippstavlen",
      or_choose_content_experience: "Eller velg en spesifikk innholdserfaring:",
      step_two_header: "Legg det til i webinarpresentasjonen, e-posten og sosiale innlegg",
      step_two_description: "Legg det til i webinarpresentasjonen, e-posten, sosiale medieinnlegg... hvor du enn er i kontakt med handelspartnere.",
      step_three_header: "Legg til forklarende tekst",
      step_three_description: "Legg til forklarende tekst for å hjelpe partnere med å forstå hvorfor du deler lenkene og QR-kodene.",
      step_three_tip: "Ikke glem å lime inn linken og/eller QR-koden!",
      sales_example_one: "Det er den enkleste måten å selge :partnerName på. Klikk for å komme i gang!",
      sales_example_two: "Klikk for å umiddelbart co-brande og dele vår nyeste innholdserfaring.",
      sales_example_three: "Klikk for å umiddelbart begynne å selge :partnerName",
      just_a_moment: "Et øyeblikk",
      thats_strange: "Det er merkelig...",
      invalid_magic_link: "Denne magiske linken er ugyldig. Klikk på magisk link-knappen igjen eller lim inn den tilknyttede magiske URL-en",
      oops: "Oops!",
      magic_link_used:
        "Magiske lenker er kun for engangsbruk, og denne har allerede blitt brukt. Vennligst skriv inn e-postadressen din på nytt for å motta en ny magisk link.",
      get_new_magic_link: "Få din nye magiske link!",
      new_magic_link_sent: "Din magiske link har utløpt, så vi har sendt deg en ny.",
      magic_link_check_email: "Sjekk e-posten din og klikk for å logge inn",
      cobrand_our_website: "Co-brand nettstedet vårt",
      required_field: "Dette feltet er påkrevd.",
      sales_example_description: "Her er noen eksempler for å hjelpe deg i gang:",
      confirm: "Bekreft",
    },
  },
}));
